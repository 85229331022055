import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Typography,
  SvgIcon,
  Stack,
  Box,
  Checkbox,
  Select,
  MenuItem,
  Skeleton,
  ListSubheader,
  CircularProgress,
  Link,
  TextField,
} from "@mui/material";
import { useTheme } from "styled-components";
import { loadCSS } from "fg-loadcss";
import {
  BannerIcon,
  CardNodeBannerFooter,
  PurchaseButtom,
  RedirectButtom,
  SuperNodeBannerBuyCardBuying,
  SuperNodeBannerContent,
  SuperNodeBannerFooter,
  SuperNodeBannerWrapper,
} from "../styles/SuperNodeBanner";
import img1 from "../../../../../assets/images/img1.png";
import img2 from "../../../../../assets/images/img2.png";
import img3 from "../../../../../assets/images/img3.png";
import img4 from "../../../../../assets/images/img4.png";
import img5 from "../assets/sucess-transaction-icon.png";

import eth1 from "../assets/Ethereum ETH.svg";
import ethBlast from "../assets/Ethereum ETH-blast.svg";
import usdtBnb from "../assets/usdt-bnb.svg";
import stargateLogo from "../assets/stargate_logo.svg";
import bnbBnb from "../assets/bnb-bnb-logo.svg";
import usdt from "../assets/usdt.svg";

import cloudImage from "../../../../../assets/images/cloudImage.png";
import leftArrow from "../assets/left-arrow-node-banner.png";

import clock from "../assets/clock.svg";

import dentro from "../../../../../assets/images/dentro.svg";
import fora from "../../../../../assets/images/fora.svg";

import pay from "../../../../../assets/images/tether-usdt-seeklogo 1.png";
import eth from "../../../../../assets/images/ethereum-eth 1.png";
import coin3 from "../../../../../assets/images/coin3.png";
import coin4 from "../../../../../assets/images/coin4.png";

import failure from "../../../../../assets/images/failure.png";
import waitingTX from "../../../../../assets/images/waitingTX.png";

import { ReactComponent as Lock } from "../../../../../assets/icons/lock.svg";

import { ReactComponent as minusIcon } from "../../../../../assets/icons/minusIcon.svg";
import { ReactComponent as plusIcon } from "../../../../../assets/icons/plusIcon.svg";

import { ReactComponent as minusIconBlack } from "../../../../../assets/icons/minus_icon_black.svg";
import { ReactComponent as plusIconBlack } from "../../../../../assets/icons/plus_icon_black.svg";

import store, { useTypedSelector } from "../../../../../store/store";

import pointerRoullete from "../../../../../assets/images/pointerRoullete.png";

import { toast } from "react-toastify";

import { setActiveProfile } from "../../../../../store/appState/appStateActions";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  listeningGuardianPurchaseHook,
  platform,
} from "../../../../../API/platform";
import { useFetchData } from "../../../../../API/refresh";
import ProfileTest from "../../profileTEST";

import conetLogo3d from "../assets/CONET LOGO 3D 5.png";
import guardianLogoOn from "../assets/guardian.svg";
import guardianLogoOnDark from "../assets/guardianDark.svg";
import guardianLogoOff from "../assets/guardianOff.svg";
import guardianEndImg from "../assets/guardianEnd.svg";
import shieldEndImg from "../assets/shieldEnd.svg";
import sentinelEndImg from "../assets/sentinelEnd.png";

import guardianNftLogo from "../assets/sentinelNftLogo.png";
import sentinelNftLogo from "../assets/sentinelNftLogo.png";
import conetianNftLogo from "../assets/conetianNftLogo.svg";
import pioneerNftLogo from "../assets/pioneerNftLogo.svg";

import "../styles/superNodeBanner.css";
import { CopyToClipboard } from "../../../../../utilities/utilities";
import { MdOutlineContentCopy } from "react-icons/md";
import PlanCard from "../components/PlanCard";
import { motion } from "framer-motion";
import { useUserProfile } from "../../../../../API/useUserProfile";
import { useRefreshStore } from "../../../../../API/useRefreshStore";

enum PurchaseOptions {
  SUPERNODE = "supernode",
  NFT = "nft"
};

enum NFTPlans {
  GUARDIAN = "guardian",
  SENTINEL = "sentinel",
  CONETIAN = "conetian",
  PIONEER = 'pioneer'
}

interface ISelectedNfts {
  title: string;
  quantity: number;
}

export const SuperNodeBanner: React.FC = (props: any) => {
  const intl = useIntl();
  const { colors } = useTheme().ui;
  const [isBuying, setIsBuying] = useState<boolean>(false); //default => false
  const [guardianQuant, setGuardianQuant] = useState<number>(1);
  const [totalPrice, setTotalPrice] = useState<number | undefined>(); //default ()
  const theme = useTheme();
  const [multiplierCNTP, setMultiplierCNTP] = useState<number>(1);
  const [isSupernodeAgreementSigned, setIsSupernodeAgreementSigned] = useState<boolean>(false);
  const [isSupernodeAgreementOpened, setIsSupernodeAgreementOpen] = useState<boolean>(false);
  const [purchaseAble, setPurchaseAble] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false); //default => false
  const [selectedCoin, setSelectedCoin] = useState<string>("none");
  const [displayCoin, setDisplayCoin] = useState<string>("usdt");
  const [coinImage, setCoinImage] = useState<string>("");
  const [conetPlatform, setConetPlatform] = useState<any>("");
  const [workerLoading, setWorkerLoading] = useState(0);
  const conet_platform = new platform(setConetPlatform, setWorkerLoading);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingFX, setIsLoadingFX] = useState<boolean>(false);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [buyingLoading, setBuyingLoading] = useState<boolean>(false);
  const [isLoadingPrices, setIsLoadingPrices] = useState<boolean>(false);
  const [gasFee, setGasFee] = useState<string | null>("0");
  const [guardianPriceByCoin, setGuardianPriceByCoin] = useState<number>(1250);
  const [timer, setTimer] = useState(1200);
  const [asset, setAsset] = useState("");
  const [purchaseStatus, setPurchaseStatus] = useState<number>(0); //default 0
  const [purchaseMode, setPurchaseMode] = useState<string>(""); //default ''
  const [txCode, setTxCode] = useState<string>("");
  const [selectedSupernodePlan, setSelectedSupernodePlan] = useState<string>("guardian");
  const [unlock, setUnlock] = useState(false);
  const [otherAssets, setOtherAssets] = useState();
  const [isGettingOtherAssets, setGettingOtherAssets] =
    useState<boolean>(false);


  const [selectedPurchaseOption, setSelectedPurchaseOption] = useState<PurchaseOptions>(PurchaseOptions.SUPERNODE);

  //NFT states
  const [selectedNFTPlan, setSelectedNFTPlan] = useState<NFTPlans>(NFTPlans.GUARDIAN);
  const [nftQuant, setNftQuant] = useState<number>(0);
  const [nftPriceByCoin, setNftPriceByCoin] = useState<number>(1200);
  const [isNftAgreementSigned, setIsNftAgreementSigned] = useState<boolean>(false);
  const [isNftAgreementOpened, setIsNftAgreementOpen] = useState<boolean>(false);
  const [selectedNFts, setSelectedNfts] = useState<ISelectedNfts[]>([])
  const [nftTotal, setNftTotal] = useState<number>(0)
  const [agentWalletAddress, setAgentWalletAddress] = useState<string>('');

  const nftPrices = {
    guardian: 1200,
    sentinel: 700,
    conetian: 400,
    pioneer: 100
  }
  function calculateTotalNfts() {
    let totalQuantity = 0;
    let totalPrice = 0;


    for (let nft of selectedNFts) {

      totalQuantity += nft.quantity;


      if (nftPrices[nft.title]) {
        totalPrice += nft.quantity * nftPrices[nft.title];
      }
    }
    setNftTotal(totalPrice)
    setNftQuant(totalQuantity)
  }



  useEffect(() => {
    calculateTotalNfts()
  }, [selectedNFts])

  const walletStore = useRefreshStore((state) => state.wallets);
  const { userProfile, setUserProfile } = useUserProfile();

  const currentlyOwned =
    walletStore && walletStore.length
      ? walletStore?.filter((w) => w.isNode === true).length
      : 0;

  const profileIndex = useTypedSelector(
    (state) => state.appState.activeProfile
  );
  const [currentProfile, setCurrentProfile] = useState(profileIndex);
  const [profile, setProfile] = useState();

  const oracleDataStore = useRefreshStore((state) => state.oracleAssets);
  useEffect(() => {
    if (selectedCoin === "none") {
      setTotalPrice(1250 * guardianQuant);
      setDisplayCoin("usdt");
    } else {
      setTotalPrice(parseFloat(guardianQuant * guardianPriceByCoin));
    }
  }, [selectedCoin, guardianQuant]);

  const supernodePlans = [
    {
      title: "Guardian Plan",
      image:
        selectedSupernodePlan == "guardian"
          ? localStorage.getItem("mui-mode") === "light"
            ? guardianLogoOnDark
            : guardianLogoOn
          : guardianLogoOff,
      plan: "guardian",
    },
  ];

  const nftPlans = [
    {
      title: "Guardian NFT",
      image: guardianNftLogo,
      plan: "guardian",
    },
    {
      title: "Sentinel NFT",
      image: sentinelNftLogo,
      plan: "sentinel",
    },
    {
      title: "CoNETian NFT",
      image: conetianNftLogo,
      plan: "conetian",
    },
    {
      title: 'Pioneer NFT',
      image: pioneerNftLogo,
      plan: 'pioneer'
    }
  ];

  function renderPlanLogo() {
    switch (selectedSupernodePlan) {
      case "guardian":
        return guardianEndImg;
        break;
      case "sentinel":
        return sentinelEndImg;
        break;
      default:
        return shieldEndImg;
        break;
    }
  }

  async function getAssets() {
    if (isGettingOtherAssets) return;

    setGettingOtherAssets(true);
    const res = await conet_platform.getAllOtherAssets();

    if (!res) {
      toast.error("Error on retrieving data, try again later", {
        position: "bottom-center",
        autoClose: 1500,
      });
    } else {
      setOtherAssets(res[0].tokens);
    }
    setGettingOtherAssets(false);
  }

  async function unlockGetAssets() {
    if (isGettingOtherAssets) return;
    await getAssets();
    setUnlock(true);
  }

  useEffect(() => {
    listeningGuardianPurchaseHook(setPurchaseStatus);
    async function getAllAssets() {
      await conet_platform.getAllOtherAssets();
    }
    getAllAssets();
  });

  useEffect(() => {
    const updateProfile = () => {
      const keyID = profileIndex?.keyID?.toLowerCase() || "0";
      const profiles = walletStore && walletStore.length > 0 ? walletStore : [];
      if (!profiles) return false;
      const index = profiles.findIndex((n) => n.keyID.toLowerCase() === keyID);
      if (index < 0) {
        return;
      }
      setProfile(profiles[index]);
      setCurrentProfile(profiles[index]);
    };
    updateProfile();
  }, [walletStore]);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    currency: "USD",
  });

  // Devider layout configuration
  const borderDevider =
    localStorage.getItem("mui-mode") === "light" ? "#FFFFFF" : "#111113";
  const circleDevider =
    localStorage.getItem("mui-mode") === "light" ? "#f6f8ff" : "#303032";
  const colorDevider =
    localStorage.getItem("mui-mode") === "light" ? "#49454f" : "#111113";

  const pointerProperties = {
    src: pointerRoullete,
    style: {
      top: "calc(50% - 16px)",
      width: "37px",
      height: "31px",
      right: "-10px",
    },
  };

  const authKey = useTypedSelector((state) => state.appState.pass);

  const updateProfiles = async () => {
    const res_getall = walletStore && walletStore.length ? walletStore : [];
    for (let i = 0; i < res_getall[0].length; i++) {
      if (
        res_getall[0][i]?.keyID &&
        userProfile?.keyID == res_getall[0][i]?.keyID
      ) {
        await store.dispatch(setActiveProfile(res_getall[0][i]));
        setUserProfile(res_getall[(0)[i]]);
      }
    }
  };

  useEffect(() => {
    if (purchaseStatus == 4) {
      setTimeout(() => {
        updateProfiles();
      }, 2000);
    }
  }, [purchaseStatus]);

  const formatTime = (time: any) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleSpinClick = () => {
    if (!mustSpin) {
      const newPrizeNumber = Math.floor(Math.random() * wheelData.length);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    }
  };
  const selectAsset = (asset: string) => {
    switch (asset.toLowerCase()) {
      case "arb":
        setAsset("aeth");
        break;
      case "bsc":
        setAsset("bnb");
        break;
      case "eth":
        setAsset("eth");
        break;
      default:
        setAsset("conet");
        break;
    }
  };
  const handleChange = async (event: any) => {
    changeCoinImage(event.target.value);
    selectAsset(event.target.value.split("-")[0]);
    setSelectedCoin(event.target.value.split("-")[1]);
  };

  const currentUrl = window.location.href;

  useEffect(() => {
    function verifyPurchase(url: string) {
      const regex = /purchase=true/;
      const isPurchaseTrue = regex.test(url);
      setPurchaseAble(isPurchaseTrue);
    }
    verifyPurchase(currentUrl);
  }, [currentUrl]);

  const confirmSupernodeBuy = async () => {
    await updateGuardianPrice();
    setTotalPrice(guardianQuant * guardianPriceByCoin);
    return guardianQuant * guardianPriceByCoin;
  };

  const confirmFXPrePurchase = async () => {
    const result = await conet_platform.fx168PrePurchase(guardianQuant);
    setTxCode(result[0]?.id.toString());
    setIsBuying(!isBuying);
    setPurchaseMode("fx168");
    setIsLoadingFX(false);
  };
  console.log('>>>>>>>>>>>', purchaseStatus)
  const confirmNftPrePurchase = async () => {
    const res = await conet_platform.prePurchase(nftQuant, String(nftTotal), userProfile, selectedCoin)
    setGasFee(res[1] !== null ? res[1] : 0)
    setIsBuying(!isBuying);
  }

  const buyNfts = async () => {
    const amountArr = [0, 0, 0, 0]
    selectedNFts.forEach(nft => {
      switch (nft.title) {
        case "guardian":
          amountArr[0] = nft.quantity;
          break;
        case "sentinel":
          amountArr[1] = nft.quantity;
          break;
        case "conetian":
          amountArr[2] = nft.quantity;
          break;
        case "pioneer":
          amountArr[3] = nft.quantity;
          break;
      }
    });
    await conet_platform.nftPurchase(agentWalletAddress, userProfile, selectedCoin, amountArr)
  }

  const confirmSupernodePrePurchase = async () => {
    await confirmSupernodeBuy();

    if (true) {
      //userProfile?.tokens[selectedCoin].balance >= guardianQuant * guardianPriceByCoin
      const res = await conet_platform.prePurchase(
        guardianQuant,
        String(guardianQuant * guardianPriceByCoin),
        userProfile,
        selectedCoin
      );
      setGasFee(res[1]);
      setIsBuying(!isBuying);
    } else {
      toast.error("Not enought balance!", {
        position: "bottom-center",
        autoClose: 1500,
      });
    }
    setIsLoading(false);
  };
  console.log(userProfile)
  const buySupernodeGuardian = async () => {
    await conet_platform.guardianPurchase(
      guardianQuant,
      String(guardianQuant * guardianPriceByCoin),
      userProfile,
      selectedCoin
    );
    setTimeout(() => {
      const { wallet, referees, leaderboard } = useFetchData();
    }, 1000);
  };
  const fxBuy = async () => {
    const res = await conet_platform.fx168PrePurchase(guardianQuant);
  };

  const updateGuardianPrice = async () => {
    setIsLoadingPrices(true);
    let coin = selectedCoin;
    switch (selectedCoin) {
      case "arb_usdt":
      case "wusdt":
      case "usdt":
        coin = "usdt";
        break;
      case "arb_eth":
      case "eth":
        coin = "eth";
        break;
      case "wbnb":
        coin = "bnb";
        break;
    }

    let currency_data;
    let coin_price;
    currency_data =
      oracleDataStore && oracleDataStore.assets
        ? oracleDataStore.assets.find((item) => item.name === coin)
        : "";
    coin_price =
      currency_data && oracleDataStore.assets ? currency_data.price : 1;
    if (selectedCoin !== "none") {
      setDisplayCoin(coin);
    }
    if (coin === "usdt") {
      setGuardianPriceByCoin(1250);
    } else {
      coin_price
        ? await setGuardianPriceByCoin(
          (1250 / parseFloat(coin_price)).toFixed(4)
        )
        : 1;
    }

    setIsLoadingPrices(false);
  };
  const updateNftPrice = async () => {
    setIsLoadingPrices(true);

    let coin = selectedCoin;

    switch (selectedCoin) {
      case "arb_usdt":
      case "wusdt":
      case "usdt":
        coin = "usdt";
        break;
      case "arb_eth":
      case "eth":
        coin = "eth";
        break;
      case "wbnb":
        coin = "bnb";
        break;
    }

    let currency_data;
    let coin_price;

    currency_data =
      oracleDataStore && oracleDataStore.assets
        ? oracleDataStore.assets.find((item) => item.name === coin)
        : "";
    coin_price =
      currency_data && oracleDataStore.assets ? currency_data.price : 1;

    if (selectedCoin !== "none") {
      setDisplayCoin(coin);
    }

    if (coin === "usdt") {
      setNftPriceByCoin(1200);
    } else {
      coin_price
        ? await setNftPriceByCoin(
          (1200 / parseFloat(coin_price)).toFixed(4)
        )
        : 1;
    }

    setIsLoadingPrices(false);
  };

  useEffect(() => {
    updateGuardianPrice();
  }, [selectedCoin]);

  useEffect(() => {
    updateNftPrice();
  }, [selectedCoin]);

  useEffect(() => {
    const fetchData = async () => {
      if (!active) return;

      const node = loadCSS(
        "https://use.fontawesome.com/releases/v6.5.1/css/all.css",
        //@ts-ignore
        document.querySelector("#font-awesome-css") || document.head.firstChild
      );

      return () => {
        active = false;
        node.parentNode!.removeChild(node);
      };
    };

    let active = true;
    fetchData();
  }, []);

  const getRefferRate = (num: number) => {
    if (num >= 100) {
      return 3;
    }

    if (num < 2) {
      return 1;
    }

    return (100 + 2 * num) / 100;
  };

  useEffect(() => {
    setMultiplierCNTP(getRefferRate(guardianQuant + currentlyOwned));
  }, [currentlyOwned]);

  function changeCoinImage(value: any) {
    switch (value) {
      case "ARB-arb_usdt":
        setCoinImage(usdtBnb);
        break;
      case "ARB-arb_eth":
        setCoinImage(eth);
        break;
      case "BSC-wusdt":
        setCoinImage(usdtBnb);
        break;
      case "BSC-wbnb":
        setCoinImage(bnbBnb);
        break;
      case "ETH-eth":
        setCoinImage(eth);
        break;
      case "ETH-usdt":
        setCoinImage(pay);
        break;
    }
  }

  useEffect(() => {
    const element = document.getElementById(`airdrop`);
    if (!element) return;

    element.innerHTML = element.innerHTML.replace(",", `<br />`);
    element.innerHTML = element.innerHTML.replace(";", `<br />`);
  }, []);

  return (
    <SuperNodeBannerWrapper margin='auto' width={"100%"}>
      <Box
        display='flex'
        flexDirection={["column", "column"]}
        p={"16px"}
        gap='16px'
      >
        <Box
          style={{
            display: "flex",
            position: "relative",
            justifyContent: "center",
          }}
        ></Box>
        <Box
          display='flex'
          justifyContent='space-between'
          flexDirection={["column", "column", "row"]}
          gap={[6, 12]}
          p={["16px", 0, 0]}
          alignItems='flex-start'
        >
          <SuperNodeBannerContent>
            <Box
              display='flex'
              width='100%'
              flexDirection={["column", "column", "row"]}
              gap={[6, 12]}
              alignItems='center'
            >
              <Stack direction='column' flex='1' spacing={4} maxWidth={"430px"}>
                <Stack spacing={1}>
                  <Typography
                    sx={{
                      textAlign: `left`,
                      width: "100%",
                      maxWidth: `281px`,
                      lineHeight: `3rem`,
                    }}
                    fontSize={["24px", "24px", "36px"]}
                  >
                    Embracing{" "}
                    <span style={{ color: " #8DA8FF", fontWeight: 700 }}>
                      {" "}
                      Decentralization{" "}
                    </span>{" "}
                    for Everyone
                  </Typography>
                </Stack>

                <Stack direction='column' gap='0.5rem'>
                  <Stack direction='row' alignItems='center' gap='0.5rem'>
                    <img width={"25px"} src={pay} />
                    <img width={"25px"} src={eth} />
                    <img width={"25px"} src={bnbBnb} />
                    <img width={"25px"} src={usdtBnb} />
                  </Stack>

                  <Typography fontSize='14px' fontWeight='600'>
                    Pay in USDT or ETH or BNB or AETH
                  </Typography>
                </Stack>

                <Stack>
                  <Typography fontSize='14px' fontWeight='600'>
                    Bring your assets from other networks
                  </Typography>

                  <motion.div
                    whileHover={{ scale: 1.0125 }}
                    whileTap={{ scale: 1.0125 }}
                    style={{ width: "100%", padding: "1px" }}
                    className='cardAbove'
                  >
                    <Link
                      href='https://stargate.finance/bridge'
                      target='_blank'
                    >
                      <Box
                        sx={{
                          borderRadius: "8px",
                          background: "#363E59",
                          padding: "8px 0px",
                          width: "100%",
                          textAlign: "center",
                          marginTop: "8px",
                          color: "white",
                          fontWeight: 800,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "4px",
                        }}
                      >
                        <img src={stargateLogo} height={25} width={25} />
                        <Typography fontSize='14px' fontWeight='600'>
                          Stargate Bridge
                        </Typography>
                      </Box>
                    </Link>
                  </motion.div>
                </Stack>

                <Stack>
                  <Typography fontSize='14px' fontWeight='600'>
                    Your Available Balance
                  </Typography>

                  {unlock ? (
                    <Box
                      style={{
                        marginTop: "8px",
                        border:
                          localStorage.getItem("mui-mode") === "light"
                            ? "1px solid #E4E2E4"
                            : "1px solid #474648",
                        padding: "24px",
                        borderRadius: "16px",
                      }}
                      maxWidth={"360px"}
                    >
                      <Typography
                        fontSize='16px'
                        fontWeight='700'
                        style={{ marginBottom: "8px" }}
                      >
                        Binance Smart Chain
                      </Typography>

                      <Stack
                        direction='row'
                        alignItems='center'
                        gap='0.5rem'
                        justifyContent={"space-between"}
                        mb={"16px"}
                        ml={"8px"}
                      >
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          gap='0.5rem'
                        >
                          <img src={usdtBnb} height={25} width={25} />
                          <Typography fontSize='14px' fontWeight='600'>
                            USDT
                          </Typography>
                        </Box>
                        <Typography fontSize='14px' fontWeight='600'>

                          {otherAssets
                            ? parseFloat(otherAssets?.wusdt?.balance).toFixed(4)
                            : 0}
                        </Typography>
                      </Stack>

                      <Stack
                        direction='row'
                        alignItems='center'
                        gap='0.5rem'
                        justifyContent={"space-between"}
                        mb={"16px"}
                        ml={"8px"}
                      >
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          gap='0.5rem'
                        >
                          <img width={"25px"} src={bnbBnb} />
                          <Typography fontSize='14px' fontWeight='600'>
                            BNB
                          </Typography>
                        </Box>
                        <Typography fontSize='14px' fontWeight='600'>
                          {" "}

                          {otherAssets
                            ? parseFloat(otherAssets?.bnb?.balance).toFixed(4)
                            : 0}
                        </Typography>
                      </Stack>

                      <Typography
                        fontSize='16px'
                        fontWeight='700'
                        style={{ marginBottom: "8px" }}
                      >
                        ETH Network
                      </Typography>
                      <Stack
                        direction='row'
                        alignItems='center'
                        gap='0.5rem'
                        justifyContent={"space-between"}
                        mb={"16px"}
                        ml={"8px"}
                      >
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          gap='0.5rem'
                        >
                          <img width={"25px"} src={eth1} />
                          <Typography fontSize='14px' fontWeight='600'>
                            ETH
                          </Typography>
                        </Box>
                        <Typography fontSize='14px' fontWeight='600'>

                          {otherAssets &&
                            !isNaN(parseFloat(otherAssets?.eth?.balance))
                            ? parseFloat(otherAssets?.eth?.balance).toFixed(4)
                            : 0}
                        </Typography>
                      </Stack>

                      <Stack
                        direction='row'
                        alignItems='center'
                        gap='0.5rem'
                        justifyContent={"space-between"}
                        mb={"16px"}
                        ml={"8px"}
                      >
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          gap='0.5rem'
                        >
                          <img width={"25px"} src={usdt} />
                          <Typography fontSize='14px' fontWeight='600'>
                            USDT
                          </Typography>
                        </Box>
                        <Typography fontSize='14px' fontWeight='600'>

                          {otherAssets &&
                            !isNaN(parseFloat(otherAssets?.usdt?.balance))
                            ? parseFloat(otherAssets?.usdt?.balance).toFixed(4)
                            : 0}
                        </Typography>
                      </Stack>

                      <Typography
                        fontSize='16px'
                        fontWeight='700'
                        style={{ marginBottom: "8px" }}
                      >
                        Arbitrum Network
                      </Typography>
                      <Stack
                        direction='row'
                        alignItems='center'
                        gap='0.5rem'
                        justifyContent={"space-between"}
                        mb={"16px"}
                        ml={"8px"}
                      >
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          gap='0.5rem'
                        >
                          <img width={"25px"} src={usdt} />
                          <Typography fontSize='14px' fontWeight='600'>
                            USDT
                          </Typography>
                        </Box>
                        <Typography fontSize='14px' fontWeight='600'>

                          {otherAssets &&
                            !isNaN(parseFloat(otherAssets?.arb_usdt?.balance))
                            ? parseFloat(
                              otherAssets?.arb_usdt?.balance
                            ).toFixed(4)
                            : 0}
                        </Typography>
                      </Stack>

                      <Stack
                        direction='row'
                        alignItems='center'
                        gap='0.5rem'
                        justifyContent={"space-between"}
                        mb={"16px"}
                        ml={"8px"}
                      >
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          gap='0.5rem'
                        >
                          <img width={"25px"} src={eth1} />
                          <Typography fontSize='14px' fontWeight='600'>
                            ETH
                          </Typography>
                        </Box>
                        <Typography fontSize='14px' fontWeight='600'>

                          {otherAssets &&
                            !isNaN(parseFloat(otherAssets?.arb_eth?.balance))
                            ? parseFloat(otherAssets?.arb_eth?.balance).toFixed(
                              4
                            )
                            : 0}
                        </Typography>
                      </Stack>

                      <PurchaseButtom
                        style={{
                          width: "100%",
                          textAlign: "center",
                          maxWidth: "300px",
                          margin: "0 auto",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "600",
                          fontSize: "15px",
                          padding: "0.65rem",
                          borderRadius: "0.75rem",
                          cursor: "pointer",
                          backgroundColor: circleDevider,
                        }}
                        onClick={() => getAssets()}
                      >
                        {!isGettingOtherAssets ? (
                          "Refresh Balance"
                        ) : (
                          <CircularProgress size={24} color='inherit' />
                        )}
                      </PurchaseButtom>
                    </Box>
                  ) : (
                    <Box
                      style={{
                        marginTop: "8px",
                        border:
                          localStorage.getItem("mui-mode") === "light"
                            ? "1px solid #E4E2E4"
                            : "1px solid #474648",
                        padding: "24px",
                        borderRadius: "8px",
                      }}
                      maxWidth={"360px"}
                      position={"relative"}
                    >
                      <Box style={{ opacity: 0.2 }}>
                        <Typography
                          fontSize='16px'
                          fontWeight='700'
                          style={{ marginBottom: "8px" }}
                        >
                          Binance Smart Chain
                        </Typography>

                        <Stack
                          direction='row'
                          alignItems='center'
                          gap='0.5rem'
                          justifyContent={"space-between"}
                          mb={"16px"}
                          ml={"8px"}
                        >
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            gap='0.5rem'
                          >
                            <img src={usdtBnb} height={25} width={25} />
                            <Typography fontSize='14px' fontWeight='600'>
                              USDT
                            </Typography>
                          </Box>
                          <Typography fontSize='14px' fontWeight='600'>
                            $0
                          </Typography>
                        </Stack>

                        <Stack
                          direction='row'
                          alignItems='center'
                          gap='0.5rem'
                          justifyContent={"space-between"}
                          mb={"16px"}
                          ml={"8px"}
                        >
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            gap='0.5rem'
                          >
                            <img width={"25px"} src={bnbBnb} />
                            <Typography fontSize='14px' fontWeight='600'>
                              BNB
                            </Typography>
                          </Box>
                          <Typography fontSize='14px' fontWeight='600'>
                            {" "}
                            $0
                          </Typography>
                        </Stack>

                        <Typography
                          fontSize='16px'
                          fontWeight='700'
                          style={{ marginBottom: "8px" }}
                        >
                          ETH Network
                        </Typography>
                        <Stack
                          direction='row'
                          alignItems='center'
                          gap='0.5rem'
                          justifyContent={"space-between"}
                          mb={"16px"}
                          ml={"8px"}
                        >
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            gap='0.5rem'
                          >
                            <img width={"25px"} src={eth1} />
                            <Typography fontSize='14px' fontWeight='600'>
                              ETH
                            </Typography>
                          </Box>
                          <Typography fontSize='14px' fontWeight='600'>
                            $0
                          </Typography>
                        </Stack>

                        <Stack
                          direction='row'
                          alignItems='center'
                          gap='0.5rem'
                          justifyContent={"space-between"}
                          mb={"16px"}
                          ml={"8px"}
                        >
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            gap='0.5rem'
                          >
                            <img width={"25px"} src={usdt} />
                            <Typography fontSize='14px' fontWeight='600'>
                              USDT
                            </Typography>
                          </Box>
                          <Typography fontSize='14px' fontWeight='600'>
                            $0
                          </Typography>
                        </Stack>

                        <Typography
                          fontSize='16px'
                          fontWeight='700'
                          style={{ marginBottom: "8px" }}
                        >
                          Arbitrum Network
                        </Typography>
                        <Stack
                          direction='row'
                          alignItems='center'
                          gap='0.5rem'
                          justifyContent={"space-between"}
                          mb={"16px"}
                          ml={"8px"}
                        >
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            gap='0.5rem'
                          >
                            <img width={"25px"} src={usdt} />
                            <Typography fontSize='14px' fontWeight='600'>
                              USDT
                            </Typography>
                          </Box>
                          <Typography fontSize='14px' fontWeight='600'>
                            $0
                          </Typography>
                        </Stack>

                        <Stack
                          direction='row'
                          alignItems='center'
                          gap='0.5rem'
                          justifyContent={"space-between"}
                          mb={"16px"}
                          ml={"8px"}
                        >
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            gap='0.5rem'
                          >
                            <img width={"25px"} src={eth1} />
                            <Typography fontSize='14px' fontWeight='600'>
                              ETH
                            </Typography>
                          </Box>
                          <Typography fontSize='14px' fontWeight='600'>
                            $0
                          </Typography>
                        </Stack>
                      </Box>

                      <Box
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          background: "#a8a8a838",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <motion.div
                          whileTap={{ scale: 0.97 }}
                          onClick={async () => {
                            unlockGetAssets();
                          }}
                        >
                          <PurchaseButtom
                            style={{
                              width: "200px",
                              textAlign: "center",
                              maxWidth: "300px",
                              margin: "0 auto",
                              justifyContent: "center",
                              alignItems: "center",
                              fontWeight: "600",
                              fontSize: "15px",
                              padding: "0.65rem",
                              borderRadius: "0.75rem",
                              cursor: "pointer",
                              backgroundColor: circleDevider,
                            }}
                            disabled={false}
                          >
                            {!isGettingOtherAssets ? (
                              "Get Assets Balance"
                            ) : (
                              <CircularProgress size={24} color='inherit' />
                            )}
                          </PurchaseButtom>
                        </motion.div>
                      </Box>
                    </Box>
                  )}
                </Stack>
              </Stack>
            </Box>
          </SuperNodeBannerContent>

          <SuperNodeBannerContent
            style={{
              width: "100%",
            }}
          >
            <Box
              display='flex'
              width='100%'
              flexDirection={["column", "column", "row"]}
              gap={[6, 12]}
              alignItems='flex-start'
              justifyContent='center'
            >
              <SuperNodeBannerBuyCardBuying
                style={{
                  backgroundColor:
                    localStorage.getItem("mui-mode") === "light"
                      ? "#FFFFFF"
                      : "#1B1B1D",
                  border:
                    localStorage.getItem("mui-mode") === "light"
                      ? "1px solid #E4E2E4"
                      : "1px solid #474648",
                  width: "100%",
                  maxWidth: "600px",
                }}
              >
                {!isBuying && (
                  <>
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'} alignItems={'center'} marginBottom={'32px'}>
                      <Box borderBottom={selectedPurchaseOption === PurchaseOptions.SUPERNODE ? '1px solid #79F8FF' : 'none'} paddingBottom={'12px'} onClick={() => setSelectedPurchaseOption(PurchaseOptions.SUPERNODE)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <Typography
                          fontSize='20px'
                          color={localStorage.getItem("mui-mode") === "light"
                            ? "#000000"
                            : "#FFFFFF"}
                          fontWeight={"700"}
                        >
                          Become a Super Node
                        </Typography>
                      </Box>

                      <Box borderBottom={selectedPurchaseOption === PurchaseOptions.NFT ? '1px solid #79F8FF' : 'none'} paddingBottom={'12px'} onClick={() => setSelectedPurchaseOption(PurchaseOptions.NFT)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <Typography
                          fontSize='20px'
                          color={localStorage.getItem("mui-mode") === "light"
                            ? "#000000"
                            : "#FFFFFF"}
                          fontWeight={"700"}
                        >
                          Purchase NFT
                        </Typography>
                      </Box>
                    </Box>

                    {selectedPurchaseOption === PurchaseOptions.SUPERNODE ? (
                      // SUPERNODE PURCHASE
                      <>
                        <Stack
                          direction='column'
                          spacing={4}
                          color={
                            localStorage.getItem("mui-mode") === "light"
                              ? "black"
                              : "white"
                          }
                        >

                          <Typography
                            marginBottom={2}
                            fontSize={16}
                            fontWeight={700}
                          >
                            Choose a plan
                          </Typography>
                          <Box
                            display={"flex"}
                            flexDirection={"row"}
                            gap={[1, 4]}
                            width={"100%"}
                            overflowX={"scroll"}
                            paddingBottom={"8px"}
                          >
                            {supernodePlans.map((p) => (
                              <PlanCard
                                plan={p.plan}
                                title={p.title}
                                image={p.image}
                                selectedPlan={selectedSupernodePlan}
                                setSelectedPlan={setSelectedSupernodePlan}
                                planType="supernode"

                              />
                            ))}
                          </Box>

                          <Box>
                            <Typography
                              variant='h6'
                              sx={{
                                fontWeight: "700",
                                fontSize: "16px",
                                mb: "12px",
                              }}
                            >
                              Current Account
                            </Typography>
                            <Box
                              position='relative'
                              display='flex'
                              flexDirection='column'
                              pb='24px'
                            >
                              <Box
                                style={{ cursor: "pointer" }}
                                display='flex'
                                alignItems='center'
                                justifyContent='space-between'
                                padding='8px 32px'
                                borderRadius='16px'
                                bgcolor={
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#F6F8FF"
                                    : "#303032"
                                }
                              >
                                <Box display='flex' gap='8px' alignItems='center'>
                                  <Box display='flex' flexDirection='column'>
                                    <Typography
                                      variant='subtitle1'
                                      fontSize={"14px"}
                                      fontWeight={"500"}
                                      color={
                                        localStorage.getItem("mui-mode") === "light"
                                          ? "#111113"
                                          : "#FFFFFF"
                                      }
                                    >
                                      {currentProfile?.data?.nickname ||
                                        intl.formatMessage({
                                          id: "platform.ProfileDropdown.CurrentProfileItem.AnonymousUser",
                                        })}
                                    </Typography>
                                    <Typography
                                      variant='body2'
                                      fontSize={"12px"}
                                      color={"#5F5E60"}
                                    >
                                      {currentProfile?.keyID?.substring(0, 2) +
                                        currentProfile?.keyID
                                          ?.substring(2, 8)
                                          .toUpperCase() +
                                        "..." +
                                        currentProfile?.keyID
                                          ?.substring(
                                            currentProfile?.keyID?.length - 6,
                                            currentProfile?.keyID?.length
                                          )
                                          .toUpperCase()}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>

                          <Box>
                            <Box
                              display={"flex"}
                              flexDirection={"row"}
                              justifyContent={"space-between"}
                            >
                              <Typography
                                variant='h6'
                                sx={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  mb: "12px",
                                }}
                              >
                                Select Quantity
                              </Typography>
                              <Typography
                                variant='h6'
                                sx={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  mb: "12px",
                                }}
                              >
                                {/* Receive @multiplier x more CNTP */}
                                Current owned: {currentlyOwned}
                              </Typography>
                            </Box>

                            <Box
                              position='relative'
                              display='flex'
                              flexDirection='column'
                              pb='24px'
                            >
                              <Box
                                style={{ cursor: "pointer" }}
                                display='flex'
                                alignItems='center'
                                justifyContent='space-between'
                                padding='8px 32px'
                                borderRadius='16px'
                                bgcolor={
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#F6F8FF"
                                    : "#303032"
                                }
                              >
                                <SvgIcon
                                  component={
                                    localStorage.getItem("mui-mode") === "light"
                                      ? minusIconBlack
                                      : minusIcon
                                  }
                                  inheritViewBox
                                  fontSize='large'
                                  onClick={() => {
                                    setMultiplierCNTP(
                                      getRefferRate(
                                        guardianQuant > 1
                                          ? guardianQuant - 1 + currentlyOwned
                                          : 1 + currentlyOwned
                                      )
                                    );
                                    guardianQuant > 1
                                      ? setGuardianQuant(guardianQuant - 1)
                                      : null;
                                  }}
                                />
                                <Typography
                                  variant='h6'
                                  sx={{ fontWeight: "400", fontSize: "24px" }}
                                >
                                  {guardianQuant}
                                </Typography>
                                <SvgIcon
                                  component={
                                    localStorage.getItem("mui-mode") === "light"
                                      ? plusIconBlack
                                      : plusIcon
                                  }
                                  inheritViewBox
                                  fontSize='large'
                                  onClick={() => {
                                    setMultiplierCNTP(
                                      getRefferRate(
                                        guardianQuant + 1 + currentlyOwned
                                      )
                                    );
                                    setGuardianQuant(guardianQuant + 1);
                                  }}
                                />
                              </Box>
                            </Box>
                          </Box>

                          <Box style={{ margin: 0, marginTop: "-8px" }}>
                            <Box
                              style={{
                                background:
                                  "linear-gradient(92.05deg, rgb(215, 117, 255) 0%, rgb(141, 168, 255) 100%)",
                                borderRadius: "8px",
                                width: "100%",
                                padding: "1px",
                                maxWidth: "280px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                bgcolor={
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#F6F8FF"
                                    : "#303032"
                                }
                                style={{
                                  width: "calc(100% - 1px)",
                                  borderRadius: "7px",
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    padding: "4px 8px",
                                  }}
                                >
                                  Booster coefficient {multiplierCNTP}x / 12s /
                                  Guardian
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Stack alignItems='center' width={"100%"}>
                            <Typography
                              width={"100%"}
                              textAlign={"left"}
                              sx={{
                                fontWeight: "700",
                                fontSize: "16px",
                                mb: "12px",
                              }}
                            >
                              Total
                            </Typography>
                            {!isLoadingPrices ? (
                              <Box display={"flex"} gap={2} alignItems={"center"}>
                                <Typography fontSize={[20, 40, 60]}>
                                  {selectedCoin == "none"
                                    ? 1250 * guardianQuant
                                    : Number.isInteger(
                                      guardianQuant * guardianPriceByCoin
                                    )
                                      ? guardianQuant * guardianPriceByCoin
                                      : (guardianQuant * guardianPriceByCoin).toFixed(
                                        4
                                      )}
                                </Typography>
                                <Typography fontSize='26px'>
                                  {displayCoin.toUpperCase()}
                                </Typography>
                              </Box>
                            ) : (
                              <Skeleton
                                variant='rectangular'
                                width={"100%"}
                                height={85}
                              />
                            )}
                          </Stack>

                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            gap={2}
                            margin={"0 auto"}
                            justifyContent={"center"}
                            mt={"16px"}
                          >
                            <Select
                              id='coin-select'
                              defaultValue={"select-token"}
                              label='ETH'
                              onChange={handleChange}
                              IconComponent={KeyboardArrowDownIcon}
                              variant='standard'
                              disableUnderline={true}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                textDecoration: "none",
                                width: "100%",
                                maxWidth: "180px",
                              }}
                            >
                              <MenuItem
                                value='select-token'
                                disabled
                                style={{ display: "none" }}
                              >
                                Select your Token
                              </MenuItem>

                              <ListSubheader
                                key={`subheader-aeth`}
                                style={{
                                  padding: "8px",
                                  fontSize: "14px",
                                  lineHeight: "14px",
                                  background: "none",
                                }}
                              >
                                Arbitrum Network
                              </ListSubheader>
                              {parseFloat(userProfile?.tokens?.arb_usdt?.balance) >=
                                (totalPrice ? totalPrice : 0) && (
                                  <MenuItem
                                    value={`${userProfile?.tokens?.arb_usdt?.network}-${userProfile?.tokens?.arb_usdt?.name}`}
                                  >
                                    <img
                                      width={"25px"}
                                      src={usdtBnb}
                                      alt='pay-icon'
                                      style={{
                                        cursor: "pointer",
                                        marginRight: "10px",
                                      }}
                                    />
                                    USDT
                                  </MenuItem>
                                )}

                              {(parseFloat(
                                userProfile?.tokens?.arb_usdt?.balance
                              ) == 0 ||
                                parseFloat(userProfile?.tokens?.arb_eth?.balance) ==
                                0) && (
                                  <ListSubheader
                                    key={`insuficient-blast`}
                                    style={{
                                      fontSize: "12px",
                                      lineHeight: "14px",
                                      background: "none",
                                      paddingBottom: "8px",
                                    }}
                                  >
                                    Insufficient funds
                                  </ListSubheader>
                                )}

                              {parseFloat(userProfile?.tokens?.arb_eth?.balance) >=
                                (totalPrice ? totalPrice : 0) && (
                                  <MenuItem
                                    value={`${userProfile?.tokens?.arb_eth?.network}-${userProfile?.tokens?.arb_eth?.name}`}
                                  >
                                    <img
                                      width={"25px"}
                                      src={eth}
                                      alt='pay-icon'
                                      style={{
                                        cursor: "pointer",
                                        marginRight: "10px",
                                      }}
                                    />
                                    ETH
                                  </MenuItem>
                                )}

                              {(parseFloat(userProfile?.tokens?.arb_eth?.balance) ==
                                0 ||
                                parseFloat(userProfile?.tokens?.arb_eth?.balance) ==
                                0) && (
                                  <ListSubheader
                                    key={`insuficient-blast`}
                                    style={{
                                      fontSize: "12px",
                                      lineHeight: "14px",
                                      background: "none",
                                      paddingBottom: "8px",
                                    }}
                                  >
                                    Insufficient funds
                                  </ListSubheader>
                                )}
                              <ListSubheader
                                key={`subheader-bnb`}
                                style={{
                                  padding: "8px",
                                  fontSize: "14px",
                                  lineHeight: "14px",
                                  background: "none",
                                }}
                              >
                                Binance Network
                              </ListSubheader>
                              {parseFloat(userProfile?.tokens?.wusdt?.balance) >=
                                (totalPrice ? totalPrice : 0) && (
                                  <MenuItem
                                    value={`${userProfile?.tokens?.wusdt?.network}-${userProfile?.tokens?.wusdt?.name}`}
                                  >
                                    <img
                                      width={"25px"}
                                      src={usdtBnb}
                                      alt='pay-icon'
                                      style={{
                                        cursor: "pointer",
                                        marginRight: "10px",
                                      }}
                                    />
                                    USDT
                                  </MenuItem>
                                )}
                              {parseFloat(userProfile?.tokens?.wusdt?.balance) ==
                                0 &&
                                parseFloat(userProfile?.tokens?.bnb?.balance) ==
                                0 && (
                                  <ListSubheader
                                    key={`insuficient-blast`}
                                    style={{
                                      fontSize: "12px",
                                      lineHeight: "14px",
                                      background: "none",
                                      paddingBottom: "8px",
                                    }}
                                  >
                                    Insufficient funds
                                  </ListSubheader>
                                )}

                              {parseFloat(userProfile?.tokens?.bnb?.balance) >=
                                (totalPrice ? totalPrice : 0) && (
                                  <MenuItem
                                    value={`${userProfile?.tokens?.wbnb?.network}-${userProfile?.tokens?.wbnb?.name}`}
                                  >
                                    <img
                                      width={"25px"}
                                      src={bnbBnb}
                                      alt='pay-icon'
                                      style={{
                                        cursor: "pointer",
                                        marginRight: "10px",
                                      }}
                                    />
                                    BNB
                                  </MenuItem>
                                )}
                              {parseFloat(userProfile?.tokens?.bnb?.balance) == 0 &&
                                parseFloat(userProfile?.tokens?.bnb?.balance) ==
                                0 && (
                                  <ListSubheader
                                    key={`insuficient-blast`}
                                    style={{
                                      fontSize: "12px",
                                      lineHeight: "14px",
                                      background: "none",
                                      paddingBottom: "8px",
                                    }}
                                  >
                                    Insufficient funds
                                  </ListSubheader>
                                )}

                              <ListSubheader
                                key={`subheader-eth`}
                                style={{
                                  padding: "8px",
                                  fontSize: "14px",
                                  lineHeight: "14px",
                                  background: "none",
                                }}
                              >
                                ETH Network
                              </ListSubheader>

                              {parseFloat(userProfile?.tokens?.usdt?.balance) >=
                                (totalPrice ? totalPrice : 0) && (
                                  <MenuItem
                                    value={`${userProfile?.tokens?.usdt?.network}-${userProfile?.tokens?.usdt?.name}`}
                                  >
                                    <img
                                      width={"25px"}
                                      src={pay}
                                      alt='pay-icon'
                                      style={{
                                        cursor: "pointer",
                                        marginRight: "10px",
                                      }}
                                    />
                                    USDT
                                  </MenuItem>
                                )}
                              {parseFloat(userProfile?.tokens?.eth?.balance) == 0 &&
                                parseFloat(userProfile?.tokens?.usdt?.balance) ==
                                0 && (
                                  <ListSubheader
                                    key={`insuficient-blast`}
                                    style={{
                                      fontSize: "12px",
                                      lineHeight: "14px",
                                      background: "none",
                                      paddingBottom: "8px",
                                    }}
                                  >
                                    Insufficient funds
                                  </ListSubheader>
                                )}

                              {parseFloat(userProfile?.tokens?.eth?.balance) >=
                                (totalPrice ? totalPrice : 0) && (
                                  <MenuItem
                                    value={`${userProfile?.tokens?.eth?.network}-${userProfile?.tokens?.eth?.name}`}
                                  >
                                    <img
                                      width={"25px"}
                                      src={eth}
                                      alt='pay-icon'
                                      style={{
                                        cursor: "pointer",
                                        marginRight: "10px",
                                      }}
                                    />
                                    ETH
                                  </MenuItem>
                                )}
                              {parseFloat(userProfile?.tokens?.eth?.balance) == 0 &&
                                parseFloat(userProfile?.tokens?.eth?.balance) ==
                                0 && (
                                  <ListSubheader
                                    key={`insuficient-blast`}
                                    style={{
                                      fontSize: "12px",
                                      lineHeight: "14px",
                                      background: "none",
                                      paddingBottom: "8px",
                                    }}
                                  >
                                    Insufficient funds
                                  </ListSubheader>
                                )}
                            </Select>
                          </Box>
                        </Stack>

                        <Box
                          marginTop={"48px"}
                          borderRadius='16px'
                          padding='8px'
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"center"}
                          alignContent={"center"}
                          bgcolor={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#F6F8FF"
                              : "#111113"
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <Box
                            display='flex'
                            gap='8px'
                            alignItems='center'
                            justifyContent='center'
                            onClick={() => setIsSupernodeAgreementOpen(prev => !prev)}
                          >
                            <Typography
                              textAlign='center'
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#5F5E60"
                                  : "#FFFFFF"
                              }
                            >
                              Agreement
                            </Typography>
                            <SvgIcon
                              style={{
                                color:
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#5F5E60"
                                    : "#FFFFFF",
                              }}
                              component={KeyboardArrowDownIcon}
                              inheritViewBox
                              sx={{ fontSize: 16 }}
                            />
                          </Box>
                          {isSupernodeAgreementOpened && (
                            <>
                              <Stack
                                style={{
                                  maxHeight: "320px",
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                }}
                                fontSize={"14px"}
                                padding={"8px 24px"}
                                gap={2}
                                color={
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#5F5E60"
                                    : "#FFFFFF"
                                }
                              >
                                <Typography fontWeight={700}>
                                  Updated IGO (Initial Guardian Offering) AGREEMENT
                                </Typography>
                                <Typography>
                                  This IGO Agreement (the "Agreement") is entered into as of [Date], by and
                                  between CoNET Network Foundation (hereinafter referred to as the "Issuer"),
                                  and the undersigned public participant (hereinafter referred to as the
                                  "Participant").
                                </Typography>
                                <Typography fontWeight={700}>
                                  1. DEFINITIONS
                                </Typography>
                                <Typography>
                                  1.1 "IGO" refers to the Initial Guardian Offering conducted by the Issuer for the
                                  sale of its CoNET Guardian Plan.
                                </Typography>
                                <Typography>
                                  1.2 "CNTP" refers to the CoNET Super Node Points, and "Guardian Plan" refers
                                  to the product offered during the IGO for participation in the CoNET network.
                                </Typography>
                                <Typography fontWeight={700}>
                                  2. PARTICIPATION
                                </Typography>
                                <Typography>
                                  2.1 The Participant agrees to purchase a Guardian Plan at the price specified in
                                  the IGO official CoNET Platform.
                                </Typography>
                                <Typography>
                                  2.2 The Participant acknowledges that participation in the IGO carries inherent
                                  risks and is solely responsible for conducting independent research before
                                  participating.
                                </Typography>
                                <Typography fontWeight={700}>
                                  3. Guardian Plan PRICE AND PAYMENT
                                </Typography>
                                <Typography>
                                  3.1 The price per Guardian Plan during the IGO is set at USDT$1,250 per
                                  Guardian Plan. Payments can be made in USDT, BUSD, ETH, or BNB.
                                </Typography>
                                <Typography>
                                  3.2 The Guardian Plan is available for sale to users worldwide, including all
                                  CoNET community members.
                                </Typography>
                                <Typography>
                                  3.3 New Benefits:
                                </Typography>
                                <Typography>
                                  Daily Earnings: Participants of the Guardian Plan will earn daily
                                  rewards by validating transactions and contributing resources
                                  (bandwidth, CPU) as part of CoNET’s Decentralized Physical
                                  Infrastructure Network (DePIN).
                                </Typography>
                                <Typography>
                                  Revenue Sharing: After the Token Generation Event (TGE), Guardian
                                  Plan holders will benefit from revenue-sharing opportunities as the
                                  network grows.
                                </Typography>
                                <Typography>
                                  No More Repurchase: The previous repurchase program has been
                                  discontinued for new purchases. However, the Guardian Plan now
                                  includes enhanced benefits such as larger airdrop allocations,
                                  validator node functionality, and earning opportunities from network
                                  services like Silent Pass VPN and CoNET’s decentralized applications.
                                </Typography>
                                <Typography>
                                  Validator and Full Node: Guardian Plan holders will function as
                                  validators and full nodes without the need for staking additional
                                  tokens, earning rewards from transaction validation and resource
                                  sharing activities.
                                </Typography>
                                <Typography>
                                  3.4 The Participant agrees to pay the total amount in full at the time of
                                  participation.
                                </Typography>
                                <Typography fontWeight={700}>
                                  4. RESPONSIBILITIES OF THE ISSUER
                                </Typography>
                                <Typography>
                                  4.1 The Issuer shall use its best efforts to conduct the IGO in a fair and
                                  transparent manner.
                                </Typography>
                                <Typography>
                                  4.2 The Issuer makes no guarantees regarding the future value of digital assets
                                  issued, and the Participant acknowledges the speculative nature of investing in
                                  digital assets.
                                </Typography>
                                <Typography fontWeight={700}>5. RISKS</Typography>
                                <Typography>
                                  5.1 The Participant acknowledges that the purchase of Guardian Plans involves
                                  risks, including but not limited to market risks, regulatory risks, and technology
                                  risks.
                                </Typography>
                                <Typography>
                                  5.2 The Participant understands and accepts that the value of Guardian Plans
                                  may fluctuate, and there is a risk of losing the entire investment.
                                </Typography>
                                <Typography fontWeight={700}>
                                  6. COMPLIANCE WITH LAWS
                                </Typography>
                                <Typography>
                                  6.1 The Participant agrees to comply with all applicable laws and regulations in
                                  their jurisdiction regarding the purchase and possession of Guardian Plans.
                                </Typography>
                                <Typography fontWeight={700}>
                                  7. DISCLAIMERS
                                </Typography>
                                <Typography>
                                  7.1 The Issuer disclaims any warranties, express or implied, regarding the
                                  Guardian Plans, Super Nodes, and the IGO, including but not limited to
                                  merchantability and fitness for a particular purpose.
                                </Typography>
                                <Typography fontWeight={700}>
                                  8. MISCELLANEOUS
                                </Typography>
                                <Typography>
                                  8.1 This Agreement constitutes the entire understanding between the parties
                                  and supersedes all prior agreements.
                                </Typography>
                                <Typography>
                                  8.2 Amendments to this Agreement must be in writing and signed by both
                                  parties.
                                </Typography>
                                <Typography>
                                  IN WITNESS WHEREOF, the parties hereto have executed this IGO Agreement
                                  as of the date first above written.
                                </Typography>
                                <Typography>CoNET Foundation</Typography>
                                <Box
                                  display={"flex"}
                                  flexDirection={"row"}
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                >
                                  <Checkbox
                                    checked={isSupernodeAgreementSigned}
                                    onChange={() =>
                                      setIsSupernodeAgreementSigned(prev => !prev)
                                    }
                                  ></Checkbox>
                                  <Typography
                                    style={{
                                      fontSize: "12px",
                                      marginTop: "5px",
                                      cursor: "pointer",
                                    }}
                                    color={
                                      localStorage.getItem("mui-mode") === "light"
                                        ? "#111113"
                                        : "#FFFFFF"
                                    }
                                  >
                                    I agree with the terms and conditions above
                                  </Typography>
                                </Box>
                              </Stack>
                            </>
                          )}
                        </Box>

                        <Stack
                          flex='1'
                          direction='column'
                          justifyContent='flex-end'
                        >
                          <PurchaseButtom
                            style={{
                              width: "100%",
                              textAlign: "center",
                              maxWidth: "300px",
                              margin: "0 auto",
                              marginTop: "32px",
                              justifyContent: "center",
                              alignItems: "center",
                              fontWeight: "600",
                              fontSize: "15px",
                              padding: "0.65rem",
                              borderRadius: "0.75rem",
                              cursor: "pointer",
                              backgroundColor: circleDevider,
                            }}
                            disabled={!isSupernodeAgreementSigned || selectedCoin === "none"}
                            onClick={async () => {
                              if (selectedCoin == "none") {
                                toast.error("Please select the desired token", {
                                  position: "bottom-center",
                                  autoClose: 1500,
                                });
                              } else {
                                setPurchaseMode("crypto");
                                setIsLoading(true);
                                setTimer(60);
                                setTimeout(() => {
                                  confirmSupernodePrePurchase();
                                }, 2000);
                              }
                            }}
                          >
                            {!isLoading ? (
                              "Purchase"
                            ) : (
                              <CircularProgress size={24} color='inherit' />
                            )}
                          </PurchaseButtom>

                          <Stack
                            flexDirection={"row"}
                            justifyContent={"center"}
                            marginTop={"12px"}
                            color={
                              localStorage.getItem("mui-mode") === "light"
                                ? "#5F5E60"
                                : "#FFFFFF"
                            }
                          >
                            <SvgIcon component={Lock} sx={{ fontSize: "30px" }} />
                            Secure payment
                          </Stack>
                        </Stack>
                      </>
                    )
                      : (
                        // NFT PURCHASE
                        <>
                          <Stack
                            direction='column'
                            spacing={4}
                            color={
                              localStorage.getItem("mui-mode") === "light"
                                ? "black"
                                : "white"
                            }
                          >
                            <Typography
                              fontSize={16}
                              fontWeight={700}
                            >
                              Choose the NFTs
                            </Typography>

                            <Box
                              display={['column', "flex"]}
                              flexDirection={"row"}
                              justifyContent={'space-around'}
                              gap={2}
                              width={"100%"}
                              overflowX={"scroll"}
                              padding={"8px"}
                            >
                              {nftPlans.slice(0, 2).map((p) => (
                                <PlanCard
                                  plan={p.plan}
                                  title={p.title}
                                  image={p.image}
                                  selectedPlan={selectedNFTPlan}
                                  setSelectedPlan={setSelectedNFTPlan}
                                  planType="nft"
                                  selectedNfts={selectedNFts}
                                  setSelectedNfts={setSelectedNfts}
                                  key={p.title}
                                />
                              ))}
                            </Box>
                            <Box
                              display={['column', "flex"]}
                              flexDirection={"row"}
                              justifyContent={'space-around'}
                              gap={2}
                              width={"100%"}
                              overflowX={"scroll"}
                              padding={"8px"}

                            >
                              {nftPlans.slice(2, 4).map((p) => (
                                <PlanCard
                                  planType="nft"
                                  plan={p.plan}
                                  title={p.title}
                                  image={p.image}
                                  selectedPlan={selectedNFTPlan}
                                  setSelectedPlan={setSelectedNFTPlan}
                                  selectedNfts={selectedNFts}
                                  setSelectedNfts={setSelectedNfts}
                                  key={p.title}
                                />
                              ))}
                            </Box>

                            <Box sx={{ marginTop: "16px !important" }}>
                              <Typography
                                variant='h6'
                                sx={{
                                  fontWeight: "400",
                                  fontSize: "14px",
                                }}
                              >
                                <b>Duration:</b> Ongoing benefits until further notice
                              </Typography>
                            </Box>

                            <Box>
                              <Typography
                                variant='h6'
                                sx={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  mb: "12px",
                                }}
                              >
                                Current Account
                              </Typography>

                              <Box
                                position='relative'
                                display='flex'
                                flexDirection='column'
                                pb='24px'
                              >
                                <Box
                                  style={{ cursor: "pointer" }}
                                  display='flex'
                                  alignItems='center'
                                  justifyContent='space-between'
                                  padding='8px 32px'
                                  borderRadius='16px'
                                  bgcolor={
                                    localStorage.getItem("mui-mode") === "light"
                                      ? "#F6F8FF"
                                      : "#303032"
                                  }
                                >
                                  <Box display='flex' gap='8px' alignItems='center'>
                                    <Box display='flex' flexDirection='column'>
                                      <Typography
                                        variant='subtitle1'
                                        fontSize={"14px"}
                                        fontWeight={"500"}
                                        color={
                                          localStorage.getItem("mui-mode") === "light"
                                            ? "#111113"
                                            : "#FFFFFF"
                                        }
                                      >
                                        {currentProfile?.data?.nickname ||
                                          intl.formatMessage({
                                            id: "platform.ProfileDropdown.CurrentProfileItem.AnonymousUser",
                                          })}
                                      </Typography>
                                      <Typography
                                        variant='body2'
                                        fontSize={"12px"}
                                        color={"#5F5E60"}
                                      >
                                        {currentProfile?.keyID?.substring(0, 2) +
                                          currentProfile?.keyID
                                            ?.substring(2, 8)
                                            .toUpperCase() +
                                          "..." +
                                          currentProfile?.keyID
                                            ?.substring(
                                              currentProfile?.keyID?.length - 6,
                                              currentProfile?.keyID?.length
                                            )
                                            .toUpperCase()}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>

                            <Box>
                              <Typography
                                variant='h6'
                                sx={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  mb: "12px",
                                }}
                              >
                                Agent Wallet
                              </Typography>

                              <Box
                                position='relative'
                                display='flex'
                                flexDirection='column'
                                pb='24px'
                                flex='1'
                              >
                                <Box
                                  style={{ cursor: "pointer" }}
                                  display='flex'
                                  alignItems='center'
                                  justifyContent='space-between'
                                  padding='8px 32px'
                                  borderRadius='16px'
                                  flex='1'
                                  bgcolor={
                                    localStorage.getItem("mui-mode") === "light"
                                      ? "#F6F8FF"
                                      : "#303032"
                                  }
                                >
                                  <Box display='flex' gap='8px' alignItems='center' flex='1'>
                                    <Box display='flex' flexDirection='column' flex='1'>
                                      <TextField
                                        variant='standard'
                                        style={{ width: "100%", height: '41px' }}
                                        placeholder='Wallet Address'
                                        value={agentWalletAddress}
                                        InputProps={{
                                          sx: {
                                            height: '100%', // Ensures the input spans the full height
                                            display: 'flex',
                                          },
                                        }}
                                        sx={{
                                          "& .MuiInputBase-input": {
                                            padding: 0, // Removes extra padding for vertical centering
                                            height: '100%', // Ensures that the input text spans the full height of the input container
                                            display: 'flex',
                                          },
                                          "& .MuiInput-underline:before": {
                                            borderBottom: "none",
                                          },
                                          "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                                            borderBottom: "none",
                                          },
                                          "& .MuiInput-underline:after": {
                                            borderBottom: "none",
                                          }
                                        }}
                                        onChange={(e) => setAgentWalletAddress(e.target.value)}
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>

                            {
                              selectedPurchaseOption !== 'nft' && (
                                <Box>

                                  <Box
                                    display={"flex"}
                                    flexDirection={"row"}
                                    justifyContent={"space-between"}
                                  >
                                    <Typography
                                      variant='h6'
                                      sx={{
                                        fontWeight: "700",
                                        fontSize: "16px",
                                        mb: "12px",
                                      }}
                                    >
                                      Select Quantity
                                    </Typography>

                                    <Typography
                                      variant='h6'
                                      sx={{
                                        fontWeight: "700",
                                        fontSize: "16px",
                                        mb: "12px",
                                      }}
                                    >
                                      {/* Receive @multiplier x more CNTP */}
                                      Current owned: {currentlyOwned}
                                    </Typography>
                                  </Box>

                                  <Box
                                    position='relative'
                                    display='flex'
                                    flexDirection='column'
                                    pb='24px'
                                  >
                                    <Box
                                      style={{ cursor: "pointer" }}
                                      display='flex'
                                      alignItems='center'
                                      justifyContent='space-between'
                                      padding='8px 32px'
                                      borderRadius='16px'
                                      bgcolor={
                                        localStorage.getItem("mui-mode") === "light"
                                          ? "#F6F8FF"
                                          : "#303032"
                                      }
                                    >
                                      <SvgIcon
                                        component={
                                          localStorage.getItem("mui-mode") === "light"
                                            ? minusIconBlack
                                            : minusIcon
                                        }
                                        inheritViewBox
                                        fontSize='large'
                                        onClick={() => {
                                          nftQuant > 1
                                            ? setNftQuant(prev => prev - 1)
                                            : null;
                                        }}
                                      />
                                      <Typography
                                        variant='h6'
                                        sx={{ fontWeight: "400", fontSize: "24px" }}
                                      >
                                        {nftQuant}
                                      </Typography>
                                      <SvgIcon
                                        component={
                                          localStorage.getItem("mui-mode") === "light"
                                            ? plusIconBlack
                                            : plusIcon
                                        }
                                        inheritViewBox
                                        fontSize='large'
                                        onClick={() => {
                                          setNftQuant(prev => prev + 1);
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              )
                            }
                            <Box>
                              <Typography sx={{
                                fontSize: '20px',
                                fontWeight: 700,
                                lineHeight: '24px',
                                letterSpacing: '0.15px',
                                marginBottom: '12px'
                              }}>Order summary</Typography>

                              <hr style={{ color: '#676768' }} />
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '12px' }}>
                                <Typography fontWeight={700} fontSize={16} sx={{ lineHeight: '24px', letterSpacing: '0.15px' }}>NFTs Total</Typography>
                                <Typography fontWeight={700} fontSize={16} sx={{ lineHeight: '24px', letterSpacing: '0.15px' }}>{nftQuant}</Typography>
                              </Box>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '12px' }}>
                                <Typography fontWeight={700} fontSize={16} sx={{ lineHeight: '24px', letterSpacing: '0.15px' }}>USDT Total</Typography>
                                <Typography fontWeight={700} fontSize={16} sx={{ lineHeight: '24px', letterSpacing: '0.15px' }}>{nftTotal}</Typography>
                              </Box>
                            </Box>

                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              gap={2}
                              margin={"0 auto"}
                              justifyContent={"center"}
                              mt={"16px"}
                            >
                              <Select
                                id='coin-select'
                                defaultValue={"select-token"}
                                label='ETH'
                                onChange={handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                variant='standard'
                                disableUnderline={true}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  textDecoration: "none",
                                  width: "100%",
                                  maxWidth: "180px",
                                }}
                              >
                                <MenuItem
                                  value='select-token'
                                  disabled
                                  style={{ display: "none" }}
                                >
                                  Select your token
                                </MenuItem>

                                <ListSubheader
                                  key={`subheader-aeth`}
                                  style={{
                                    padding: "8px",
                                    fontSize: "14px",
                                    lineHeight: "14px",
                                    background: "none",
                                  }}
                                >
                                  Arbitrum Network
                                </ListSubheader>
                                {parseFloat(userProfile?.tokens?.arb_usdt?.balance) >=
                                  (totalPrice ? totalPrice : 0) && (
                                    <MenuItem
                                      value={`${userProfile?.tokens?.arb_usdt?.network}-${userProfile?.tokens?.arb_usdt?.name}`}
                                    >
                                      <img
                                        width={"25px"}
                                        src={usdtBnb}
                                        alt='pay-icon'
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "10px",
                                        }}
                                      />
                                      USDT
                                    </MenuItem>
                                  )}

                                {(parseFloat(
                                  userProfile?.tokens?.arb_usdt?.balance
                                ) == 0 ||
                                  parseFloat(userProfile?.tokens?.arb_eth?.balance) ==
                                  0) && (
                                    <ListSubheader
                                      key={`insuficient-blast`}
                                      style={{
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                        background: "none",
                                        paddingBottom: "8px",
                                      }}
                                    >
                                      Insufficient funds
                                    </ListSubheader>
                                  )}

                                {parseFloat(userProfile?.tokens?.arb_eth?.balance) >=
                                  (totalPrice ? totalPrice : 0) && (
                                    <MenuItem
                                      value={`${userProfile?.tokens?.arb_eth?.network}-${userProfile?.tokens?.arb_eth?.name}`}
                                    >
                                      <img
                                        width={"25px"}
                                        src={eth}
                                        alt='pay-icon'
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "10px",
                                        }}
                                      />
                                      ETH
                                    </MenuItem>
                                  )}

                                {(parseFloat(userProfile?.tokens?.arb_eth?.balance) ==
                                  0 ||
                                  parseFloat(userProfile?.tokens?.arb_eth?.balance) ==
                                  0) && (
                                    <ListSubheader
                                      key={`insuficient-blast`}
                                      style={{
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                        background: "none",
                                        paddingBottom: "8px",
                                      }}
                                    >
                                      Insufficient funds
                                    </ListSubheader>
                                  )}
                                <ListSubheader
                                  key={`subheader-bnb`}
                                  style={{
                                    padding: "8px",
                                    fontSize: "14px",
                                    lineHeight: "14px",
                                    background: "none",
                                  }}
                                >
                                  Binance Network
                                </ListSubheader>
                                {parseFloat(userProfile?.tokens?.wusdt?.balance) >=
                                  (totalPrice ? totalPrice : 0) && (
                                    <MenuItem
                                      value={`${userProfile?.tokens?.wusdt?.network}-${userProfile?.tokens?.wusdt?.name}`}
                                    >
                                      <img
                                        width={"25px"}
                                        src={usdtBnb}
                                        alt='pay-icon'
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "10px",
                                        }}
                                      />
                                      USDT
                                    </MenuItem>
                                  )}
                                {parseFloat(userProfile?.tokens?.wusdt?.balance) ==
                                  0 &&
                                  parseFloat(userProfile?.tokens?.bnb?.balance) ==
                                  0 && (
                                    <ListSubheader
                                      key={`insuficient-blast`}
                                      style={{
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                        background: "none",
                                        paddingBottom: "8px",
                                      }}
                                    >
                                      Insufficient funds
                                    </ListSubheader>
                                  )}

                                {parseFloat(userProfile?.tokens?.bnb?.balance) >=
                                  (totalPrice ? totalPrice : 0) && (
                                    <MenuItem
                                      value={`${userProfile?.tokens?.wbnb?.network}-${userProfile?.tokens?.wbnb?.name}`}
                                    >
                                      <img
                                        width={"25px"}
                                        src={bnbBnb}
                                        alt='pay-icon'
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "10px",
                                        }}
                                      />
                                      BNB
                                    </MenuItem>
                                  )}
                                {parseFloat(userProfile?.tokens?.bnb?.balance) == 0 &&
                                  parseFloat(userProfile?.tokens?.bnb?.balance) ==
                                  0 && (
                                    <ListSubheader
                                      key={`insuficient-blast`}
                                      style={{
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                        background: "none",
                                        paddingBottom: "8px",
                                      }}
                                    >
                                      Insufficient funds
                                    </ListSubheader>
                                  )}

                                <ListSubheader
                                  key={`subheader-eth`}
                                  style={{
                                    padding: "8px",
                                    fontSize: "14px",
                                    lineHeight: "14px",
                                    background: "none",
                                  }}
                                >
                                  ETH Network
                                </ListSubheader>

                                {parseFloat(userProfile?.tokens?.usdt?.balance) >=
                                  (totalPrice ? totalPrice : 0) && (
                                    <MenuItem
                                      value={`${userProfile?.tokens?.usdt?.network}-${userProfile?.tokens?.usdt?.name}`}
                                    >
                                      <img
                                        width={"25px"}
                                        src={pay}
                                        alt='pay-icon'
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "10px",
                                        }}
                                      />
                                      USDT
                                    </MenuItem>
                                  )}
                                {parseFloat(userProfile?.tokens?.eth?.balance) == 0 &&
                                  parseFloat(userProfile?.tokens?.usdt?.balance) ==
                                  0 && (
                                    <ListSubheader
                                      key={`insuficient-blast`}
                                      style={{
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                        background: "none",
                                        paddingBottom: "8px",
                                      }}
                                    >
                                      Insufficient funds
                                    </ListSubheader>
                                  )}

                                {parseFloat(userProfile?.tokens?.eth?.balance) >=
                                  (totalPrice ? totalPrice : 0) && (
                                    <MenuItem
                                      value={`${userProfile?.tokens?.eth?.network}-${userProfile?.tokens?.eth?.name}`}
                                    >
                                      <img
                                        width={"25px"}
                                        src={eth}
                                        alt='pay-icon'
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "10px",
                                        }}
                                      />
                                      ETH
                                    </MenuItem>
                                  )}
                                {parseFloat(userProfile?.tokens?.eth?.balance) == 0 &&
                                  parseFloat(userProfile?.tokens?.eth?.balance) ==
                                  0 && (
                                    <ListSubheader
                                      key={`insuficient-blast`}
                                      style={{
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                        background: "none",
                                        paddingBottom: "8px",
                                      }}
                                    >
                                      Insufficient funds
                                    </ListSubheader>
                                  )}
                              </Select>
                            </Box>



                            {/*                             <Stack alignItems='center' width={"100%"}>
                              <Typography
                                width={"100%"}
                                textAlign={"left"}
                                sx={{
                                  fontWeight: "700",
                                  fontSize: "16px",
                                  mb: "12px",
                                }}
                              >
                                Total
                              </Typography>

                              {!isLoadingPrices ? (
                                <Box display={"flex"} gap={2} alignItems={"center"}>
                                  <Typography fontSize={[20, 40, 60]}>
                                    {selectedPurchaseOption !== "nft"
                                      ? 0
                                      : Number.isInteger(
                                        nftTotal
                                      )
                                        ? nftTotal
                                        : (nftTotal).toFixed(
                                          4
                                        )}
                                  </Typography>

                                  <Typography fontSize='26px'>
                                    {displayCoin.toUpperCase()}
                                  </Typography>
                                </Box>
                              ) : (
                                <Skeleton
                                  variant='rectangular'
                                  width={"100%"}
                                  height={85}
                                />
                              )}
                            </Stack> */}

                            {/*                             <Box
                              display={"flex"}
                              alignItems={"center"}
                              gap={2}
                              margin={"0 auto"}
                              justifyContent={"center"}
                              mt={"16px"}
                            >
                              <Select
                                id='coin-select'
                                defaultValue={"select-token"}
                                label='ETH'
                                onChange={handleChange}
                                IconComponent={KeyboardArrowDownIcon}
                                variant='standard'
                                disableUnderline={true}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  textDecoration: "none",
                                  width: "100%",
                                  maxWidth: "180px",
                                }}
                              >
                                <MenuItem
                                  value='select-token'
                                  disabled
                                  style={{ display: "none" }}
                                >
                                  Select your Token
                                </MenuItem>

                                <ListSubheader
                                  key={`subheader-aeth`}
                                  style={{
                                    padding: "8px",
                                    fontSize: "14px",
                                    lineHeight: "14px",
                                    background: "none",
                                  }}
                                >
                                  Arbitrum Network
                                </ListSubheader>
                                {parseFloat(userProfile?.tokens?.arb_usdt?.balance) >=
                                  (totalPrice ? totalPrice : 0) && (
                                    <MenuItem
                                      value={`${userProfile?.tokens?.arb_usdt?.network}-${userProfile?.tokens?.arb_usdt?.name}`}
                                    >
                                      <img
                                        width={"25px"}
                                        src={usdtBnb}
                                        alt='pay-icon'
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "10px",
                                        }}
                                      />
                                      USDT
                                    </MenuItem>
                                  )}

                                {(parseFloat(
                                  userProfile?.tokens?.arb_usdt?.balance
                                ) == 0 ||
                                  parseFloat(userProfile?.tokens?.arb_eth?.balance) ==
                                  0) && (
                                    <ListSubheader
                                      key={`insuficient-blast`}
                                      style={{
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                        background: "none",
                                        paddingBottom: "8px",
                                      }}
                                    >
                                      Insufficient funds
                                    </ListSubheader>
                                  )}

                                {parseFloat(userProfile?.tokens?.arb_eth?.balance) >=
                                  (totalPrice ? totalPrice : 0) && (
                                    <MenuItem
                                      value={`${userProfile?.tokens?.arb_eth?.network}-${userProfile?.tokens?.arb_eth?.name}`}
                                    >
                                      <img
                                        width={"25px"}
                                        src={eth}
                                        alt='pay-icon'
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "10px",
                                        }}
                                      />
                                      ETH
                                    </MenuItem>
                                  )}

                                {(parseFloat(userProfile?.tokens?.arb_eth?.balance) ==
                                  0 ||
                                  parseFloat(userProfile?.tokens?.arb_eth?.balance) ==
                                  0) && (
                                    <ListSubheader
                                      key={`insuficient-blast`}
                                      style={{
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                        background: "none",
                                        paddingBottom: "8px",
                                      }}
                                    >
                                      Insufficient funds
                                    </ListSubheader>
                                  )}
                                <ListSubheader
                                  key={`subheader-bnb`}
                                  style={{
                                    padding: "8px",
                                    fontSize: "14px",
                                    lineHeight: "14px",
                                    background: "none",
                                  }}
                                >
                                  Binance Network
                                </ListSubheader>

                                {parseFloat(userProfile?.tokens?.wusdt?.balance) >=
                                  (totalPrice ? totalPrice : 0) && (
                                    <MenuItem
                                      value={`${userProfile?.tokens?.wusdt?.network}-${userProfile?.tokens?.wusdt?.name}`}
                                    >
                                      <img
                                        width={"25px"}
                                        src={usdtBnb}
                                        alt='pay-icon'
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "10px",
                                        }}
                                      />
                                      USDT
                                    </MenuItem>
                                  )}

                                {parseFloat(userProfile?.tokens?.wusdt?.balance) ==
                                  0 &&
                                  parseFloat(userProfile?.tokens?.bnb?.balance) ==
                                  0 && (
                                    <ListSubheader
                                      key={`insuficient-blast`}
                                      style={{
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                        background: "none",
                                        paddingBottom: "8px",
                                      }}
                                    >
                                      Insufficient funds
                                    </ListSubheader>
                                  )}

                                {parseFloat(userProfile?.tokens?.bnb?.balance) >=
                                  (totalPrice ? totalPrice : 0) && (
                                    <MenuItem
                                      value={`${userProfile?.tokens?.wbnb?.network}-${userProfile?.tokens?.wbnb?.name}`}
                                    >
                                      <img
                                        width={"25px"}
                                        src={bnbBnb}
                                        alt='pay-icon'
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "10px",
                                        }}
                                      />
                                      BNB
                                    </MenuItem>
                                  )}

                                {parseFloat(userProfile?.tokens?.bnb?.balance) == 0 &&
                                  parseFloat(userProfile?.tokens?.bnb?.balance) ==
                                  0 && (
                                    <ListSubheader
                                      key={`insuficient-blast`}
                                      style={{
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                        background: "none",
                                        paddingBottom: "8px",
                                      }}
                                    >
                                      Insufficient funds to the gasFee
                                    </ListSubheader>
                                  )}

                                <ListSubheader
                                  key={`subheader-eth`}
                                  style={{
                                    padding: "8px",
                                    fontSize: "14px",
                                    lineHeight: "14px",
                                    background: "none",
                                  }}
                                >
                                  ETH Network
                                </ListSubheader>

                                {parseFloat(userProfile?.tokens?.usdt?.balance) >=
                                  (totalPrice ? totalPrice : 0) && (
                                    <MenuItem
                                      value={`${userProfile?.tokens?.usdt?.network}-${userProfile?.tokens?.usdt?.name}`}
                                    >
                                      <img
                                        width={"25px"}
                                        src={pay}
                                        alt='pay-icon'
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "10px",
                                        }}
                                      />
                                      USDT
                                    </MenuItem>
                                  )}
                                {parseFloat(userProfile?.tokens?.eth?.balance) == 0 &&
                                  parseFloat(userProfile?.tokens?.usdt?.balance) ==
                                  0 && (
                                    <ListSubheader
                                      key={`insuficient-blast`}
                                      style={{
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                        background: "none",
                                        paddingBottom: "8px",
                                      }}
                                    >
                                      Insufficient funds to the gasFee
                                    </ListSubheader>
                                  )}

                                {parseFloat(userProfile?.tokens?.eth?.balance) >=
                                  (totalPrice ? totalPrice : 0) && (
                                    <MenuItem
                                      value={`${userProfile?.tokens?.eth?.network}-${userProfile?.tokens?.eth?.name}`}
                                    >
                                      <img
                                        width={"25px"}
                                        src={eth}
                                        alt='pay-icon'
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "10px",
                                        }}
                                      />
                                      ETH
                                    </MenuItem>
                                  )}

                                {parseFloat(userProfile?.tokens?.eth?.balance) == 0 &&
                                  parseFloat(userProfile?.tokens?.eth?.balance) ==
                                  0 && (
                                    <ListSubheader
                                      key={`insuficient-blast`}
                                      style={{
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                        background: "none",
                                        paddingBottom: "8px",
                                      }}
                                    >
                                      Insufficient funds to the gasFee
                                    </ListSubheader>
                                  )}
                              </Select>
                            </Box> */}
                          </Stack>

                          <Box
                            marginTop={"48px"}
                            borderRadius='16px'
                            padding='8px'
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            alignContent={"center"}
                            bgcolor={
                              localStorage.getItem("mui-mode") === "light"
                                ? "#F6F8FF"
                                : "#111113"
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <Box
                              display='flex'
                              gap='8px'
                              alignItems='center'
                              justifyContent='center'
                              onClick={() => setIsNftAgreementOpen(prev => !prev)}
                            >
                              <Typography
                                textAlign='center'
                                color={
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#5F5E60"
                                    : "#FFFFFF"
                                }
                              >
                                Agreement
                              </Typography>
                              <SvgIcon
                                style={{
                                  color:
                                    localStorage.getItem("mui-mode") === "light"
                                      ? "#5F5E60"
                                      : "#FFFFFF",
                                }}
                                component={KeyboardArrowDownIcon}
                                inheritViewBox
                                sx={{ fontSize: 16 }}
                              />
                            </Box>
                            {isNftAgreementOpened && (
                              <>
                                <Stack
                                  style={{
                                    maxHeight: "320px",
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                  }}
                                  fontSize={"14px"}
                                  padding={"8px 24px"}
                                  gap={2}
                                  color={
                                    localStorage.getItem("mui-mode") === "light"
                                      ? "#5F5E60"
                                      : "#FFFFFF"
                                  }
                                >
                                  <Typography fontWeight={700}>
                                    NFT Token Presale Purchase Agreement
                                  </Typography>
                                  <Typography>
                                    This NFT Token Presale Purchase Agreement (the "Agreement") is entered into
                                    as of 09/25/2024, by and between CoNET Network Foundation (hereinafter
                                    referred to as the "Issuer"), and the undersigned public participant (hereinafter
                                    referred to as the "Participant").
                                  </Typography>
                                  <Typography fontWeight={700}>
                                    1. DEFINITIONS
                                  </Typography>
                                  <Typography>
                                    1.1 "NFT Token Presale" refers to the sale of non-fungible tokens (NFTs)
                                    conducted by the Issuer for the purpose of participating in the CoNET network
                                    and its ecosystem.
                                  </Typography>
                                  <Typography>
                                    1.2 "NFT" refers to the unique digital asset offered by the Issuer, representing
                                    participation rights, benefits, and incentives associated with the CoNET
                                    network
                                  </Typography>
                                  <Typography>
                                    1.3 "$CONET" refers to the CoNET tokens to be distributed after the Token
                                    Generation Event (TGE).
                                  </Typography>
                                  <Typography fontWeight={700}>
                                    2. PARTICIPATION
                                  </Typography>
                                  <Typography>
                                    2.1 The Participant agrees to purchase the NFT at the price specified in the
                                    official CoNET Presale Platform.
                                  </Typography>
                                  <Typography>
                                    2.2 The Participant acknowledges that participation in the NFT Token Presale
                                    carries inherent risks and is solely responsible for conducting independent
                                    research before purchasing.
                                  </Typography>
                                  <Typography fontWeight={700}>
                                    3. PRICE AND PAYMENT
                                  </Typography>
                                  <Typography>
                                    3.1 The price for each NFT in the presale phase is variable, ranging from $0.06
                                    to $0.10 per $CONET token equivalent depending on the amount of tokens
                                  </Typography>
                                  <Typography>
                                    3.2 The NFTs are available for sale to users worldwide, including all CoNET
                                    community members.
                                  </Typography>
                                  <Typography fontWeight={700}>
                                    4. BENEFITS OF THE NFT PURCHASE
                                  </Typography>
                                  <Typography>
                                    4.1 Validator Node Opportunity: Selected NFTs include the rights to operate a
                                    Validator Node on the Arbitrum chain, allowing holders to earn daily rewards in
                                    Ethereum (ETH) from transaction validation fees. Validator Nodes are limited,
                                    providing exclusive earning potential for participants.
                                  </Typography>
                                  <Typography>
                                    4.2 Exclusive Benefits: NFT holders will receive an amount of presale $CONET
                                    tokens, the right to become a validator, and a discount on the Power Card
                                    purchase. They will also receive larger airdrop allocations of $CONET tokens,
                                    exclusive whitelist spots for future releases, and other unique opportunities
                                    within the CoNET ecosystem.
                                  </Typography>
                                  <Typography fontWeight={700}>5. RESPONSIBILITIES OF THE ISSUER</Typography>
                                  <Typography>
                                    5.1 The Issuer shall use its best efforts to conduct the NFT Token Presale in a
                                    fair and transparent manner.
                                  </Typography>
                                  <Typography>
                                    5.2 The Issuer makes no guarantees regarding the future value of the NFTs or
                                    $CONET tokens, and the Participant acknowledges the speculative nature of
                                    investing in digital assets.
                                  </Typography>
                                  <Typography fontWeight={700}>
                                    6. RISKS
                                  </Typography>
                                  <Typography>
                                    6.1 The Participant acknowledges that the purchase of NFTs involves risks,
                                    including but not limited to market risks, regulatory risks, and technology risks.
                                  </Typography>
                                  <Typography>
                                    6.2 The Participant understands and accepts that the value of NFTs and
                                    $CONET tokens may fluctuate, and there is a risk of losing the entire
                                    investment.
                                  </Typography>
                                  <Typography fontWeight={700}>
                                    7. COMPLIANCE WITH LAWS
                                  </Typography>
                                  <Typography>
                                    7.1 The Participant agrees to comply with all applicable laws and regulations in
                                    their jurisdiction regarding the purchase and possession of NFTs and digital
                                    assets.
                                  </Typography>
                                  <Typography fontWeight={700}>
                                    8. DISCLAIMERS
                                  </Typography>
                                  <Typography>
                                    8.1 The Issuer disclaims any warranties, express or implied, regarding the NFTs
                                    and the presale, including but not limited to merchantability and fitness for a
                                    particular purpose.
                                  </Typography>
                                  <Typography fontWeight={700}>
                                    8. MISCELLANEOUS
                                  </Typography>
                                  <Typography>
                                    9.1 This Agreement constitutes the entire understanding between the parties
                                    and supersedes all prior agreements.
                                  </Typography>
                                  <Typography>
                                    9.2 Amendments to this Agreement must be in writing and signed by both
                                    parties.
                                  </Typography>
                                  <Typography>
                                    IN WITNESS WHEREOF, the parties hereto have executed this NFT Token
                                    Presale Purchase Agreement as of the date first above written.
                                  </Typography>
                                  <Typography>CoNET Foundation</Typography>


                                  <Box
                                    display={"flex"}
                                    flexDirection={"row"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                  >
                                    <Checkbox
                                      checked={isNftAgreementSigned}
                                      onChange={() =>
                                        setIsNftAgreementSigned(prev => !prev)
                                      }
                                    ></Checkbox>
                                    <Typography
                                      style={{
                                        fontSize: "12px",
                                        marginTop: "5px",
                                        cursor: "pointer",
                                      }}
                                      color={
                                        localStorage.getItem("mui-mode") === "light"
                                          ? "#111113"
                                          : "#FFFFFF"
                                      }
                                    >
                                      I agree with the terms and conditions above
                                    </Typography>
                                  </Box>
                                </Stack>
                              </>
                            )}
                          </Box>

                          <Stack
                            flex='1'
                            direction='column'
                            justifyContent='flex-end'
                          >
                            <PurchaseButtom
                              style={{
                                width: "100%",
                                textAlign: "center",
                                maxWidth: "300px",
                                margin: "0 auto",
                                marginTop: "32px",
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: "600",
                                fontSize: "15px",
                                padding: "0.65rem",
                                borderRadius: "0.75rem",
                                cursor: "pointer",
                                backgroundColor: circleDevider,
                              }}
                              disabled={(!isNftAgreementSigned || selectedCoin === 'none' || !agentWalletAddress)}
                              onClick={async () => {
                                if (selectedCoin == "none") {
                                  toast.error("Please select the desired token", {
                                    position: "bottom-center",
                                    autoClose: 1500,
                                  });
                                } else {
                                  setPurchaseMode("crypto");
                                  setIsLoading(true);
                                  setIsBuying(true)
                                  setTimer(60);
                                  setTimeout(() => {
                                    confirmNftPrePurchase();
                                  }, 2000);
                                }
                              }}
                            >
                              {!isLoading ? (
                                "Purchase"
                              ) : (
                                <CircularProgress size={24} color='inherit' />
                              )}
                            </PurchaseButtom>

                            <Stack
                              flexDirection={"row"}
                              justifyContent={"center"}
                              marginTop={"12px"}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#5F5E60"
                                  : "#FFFFFF"
                              }
                            >
                              <SvgIcon component={Lock} sx={{ fontSize: "30px" }} />
                              Secure payment
                            </Stack>
                          </Stack>
                        </>
                      )
                    }
                  </>
                )}

                {isBuying && purchaseMode == "fx168" && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                    gap={8}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        maxWidth: "320px",
                      }}
                      gap={2}
                    >
                      <Box
                        sx={{
                          maxWidth: "160px",
                          width: "100%",
                          height: "160px",
                          borderRadius: "50%",
                          border: "5px solid white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          width={"100px"}
                          src={waitingTX}
                          alt='success'
                          width='32px'
                        />
                      </Box>
                      <Typography
                        style={{ fontSize: "20px", textAlign: "center" }}
                        color={
                          localStorage.getItem("mui-mode") === "light"
                            ? "#111113"
                            : "white"
                        }
                      >
                        Copy your Order Number
                      </Typography>
                      <Typography
                        style={{ fontSize: "14px", textAlign: "center" }}
                        color={
                          localStorage.getItem("mui-mode") === "light"
                            ? "#111113"
                            : "#FFFFFF"
                        }
                      >
                        Complete your purchase by copying the Order Number
                        below, then paste it in the Fx168 page.
                      </Typography>
                    </Box>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      gap={4}
                    >
                      <div
                        style={{
                          display: `flex`,
                          color: theme.ui.colors.primary,
                          cursor: "pointer",
                        }}
                        onClick={() => CopyToClipboard(txCode)}
                      >
                        <Typography
                          style={{ fontSize: "14px" }}
                          color={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#111113"
                              : "#3399FF"
                          }
                        >
                          {txCode}
                        </Typography>
                        <MdOutlineContentCopy style={{ marginLeft: "4px" }} />
                      </div>
                      <Typography
                        style={{
                          fontSize: "14px",
                          padding: "16px 32px",
                          background: "rgba(247, 189, 37, 0.05)",
                          borderRadius: "8px",
                          marginTop: "32px",
                        }}
                        color={"#F5B904"}
                      >
                        Select {guardianQuant} nodes in the FX168 page
                      </Typography>

                      <Typography
                        style={{
                          fontSize: "14px",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        color={
                          localStorage.getItem("mui-mode") === "light"
                            ? "#111113"
                            : "#3399FF"
                        }
                        onClick={async () => {
                          setPurchaseMode("");
                          setIsBuying(false);
                          setTotalPrice(undefined);
                          setConfirm(false);
                        }}
                      >
                        Dismiss
                      </Typography>
                      <RedirectButtom
                        style={{
                          width: "100%",
                          textAlign: "center",
                          maxWidth: "300px",
                          margin: "0 auto",
                          marginTop: "16px",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: "600",
                          fontSize: "15px",
                          padding: "16px",
                          borderRadius: "0.75rem",
                        }}
                        href='https://cashier.alphapay.com/commodity/details/order/113/100000071'
                        target='_blank'
                      >
                        Access Fx168 payment page
                      </RedirectButtom>
                    </Box>
                  </Box>
                )}

                {isBuying &&
                  selectedPurchaseOption === 'nft' &&
                  purchaseMode == "crypto" &&
                  nftTotal &&
                  nftQuant &&

                  !confirm &&
                  !buyingLoading && (
                    <Box>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginLeft: "0px",
                        }}
                      >
                        <img
                          src={leftArrow}
                          alt='back'
                          height={30}
                          width={30}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setIsBuying(false);
                            setTotalPrice(undefined);
                          }}
                        />
                        <Typography
                          fontSize='24px'
                          color='#8DA8FF'
                          fontWeight={"700"}
                        >
                          Confirm your order
                        </Typography>
                      </div>

                      <div>
                        <Typography
                          style={{
                            marginBottom: "8px",
                            marginTop: "40px",
                            fontWeight: 700,
                            fontSize: "16px",
                          }}
                          color={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#111113"
                              : "#FFFFFF"
                          }
                        >
                          Paying with
                        </Typography>
                        <Box
                          style={{ cursor: "pointer" }}
                          display='flex'
                          alignItems='center'
                          justifyContent='space-between'
                          padding='16px 32px'
                          borderRadius='16px'
                          bgcolor={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#F6F8FF"
                              : "#303032"
                          }
                        >
                          <Typography
                            variant='h6'
                            sx={{
                              fontWeight: "400",
                              fontSize: "20px",
                              color: "#fff",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={coinImage}
                                alt='pay-icon'
                                style={{
                                  cursor: "pointer",
                                  marginRight: "10px",
                                  height: "25px",
                                  width: "25px",
                                }}
                              />

                              <Typography
                                color={
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#111113"
                                    : "#FFFFFF"
                                }
                              >
                                {displayCoin.toUpperCase()}
                              </Typography>
                            </div>
                          </Typography>
                        </Box>
                        <Typography
                          style={{
                            marginBottom: "8px",
                            marginTop: "40px",
                            fontWeight: 700,
                            fontSize: "16px",
                          }}
                          color={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#111113"
                              : "#FFFFFF"
                          }
                        >
                          Wallet
                        </Typography>
                        <Box
                          style={{ cursor: "pointer" }}
                          display='flex'
                          alignItems='center'
                          justifyContent='space-between'
                          padding='8px 32px'
                          borderRadius='16px'
                          bgcolor={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#F6F8FF"
                              : "#303032"
                          }
                        >
                          <Box display='flex' gap='8px' alignItems='center'>
                            <Box display='flex' flexDirection='column'>
                              <Typography
                                variant='subtitle1'
                                fontSize={"14px"}
                                fontWeight={"500"}
                                color={
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#111113"
                                    : "#FFFFFF"
                                }
                              >
                                {currentProfile?.data?.nickname ||
                                  intl.formatMessage({
                                    id: "platform.ProfileDropdown.CurrentProfileItem.AnonymousUser",
                                  })}
                              </Typography>
                              <Typography
                                variant='body2'
                                fontSize={"12px"}
                                color={"#5F5E60"}
                              >
                                {currentProfile?.keyID?.substring(0, 2) +
                                  currentProfile?.keyID
                                    ?.substring(2, 8)
                                    .toUpperCase() +
                                  "..." +
                                  currentProfile?.keyID
                                    ?.substring(
                                      currentProfile?.keyID?.length - 6,
                                      currentProfile?.keyID?.length
                                    )
                                    .toUpperCase()}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Typography
                          style={{
                            marginBottom: "8px",
                            marginTop: "40px",
                            fontWeight: 700,
                            fontSize: "16px",
                          }}
                          color={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#111113"
                              : "#FFFFFF"
                          }
                        >
                          Agent Wallet
                        </Typography>
                        <Box
                          style={{ cursor: "pointer" }}
                          display='flex'
                          alignItems='center'
                          justifyContent='space-between'
                          padding='8px 32px'
                          borderRadius='16px'
                          bgcolor={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#F6F8FF"
                              : "#303032"
                          }
                        >
                          <Box display='flex' gap='8px' alignItems='center'>
                            <Box display='flex' flexDirection='column'>
                              <Typography
                                variant='subtitle1'
                                fontSize={"14px"}
                                fontWeight={"500"}
                                color={
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#111113"
                                    : "#FFFFFF"
                                }
                              >
                                {agentWalletAddress.toUpperCase()}
                              </Typography>
                              <Typography
                                variant='body2'
                                fontSize={"12px"}
                                color={"#5F5E60"}
                              >
                                { }
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          display='flex'
                          alignItems='center'
                          width='100%'
                          gap={{ xs: 10, sm: 34 }}
                        >
                          <Typography
                            style={{
                              marginBottom: "8px",
                              marginTop: "40px",
                              fontWeight: 700,
                              fontSize: "16px",
                            }}
                            color={
                              localStorage.getItem("mui-mode") === "light"
                                ? "#111113"
                                : "#FFFFFF"
                            }
                          >
                            Summary
                          </Typography>
                          <Box
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            position='relative'
                          >
                            <img
                              src={clock}
                              height='16px'
                              style={{
                                position: "absolute",
                                bottom: "8.5px",
                                left: "-20px",
                              }}
                            />
                            <Typography
                              style={{
                                marginBottom: "8px",
                                marginTop: "40px",
                                fontWeight: 400,
                                fontSize: "12px",
                              }}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#111113"
                                  : "#FFFFFF"
                              }
                            >
                              Quote updates in{" "}
                              <span style={{ fontWeight: 700 }}>
                                {formatTime(timer)}
                              </span>
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          style={{ cursor: "pointer" }}
                          display='flex'
                          flexDirection='column'
                          gap='16px'
                          justifyContent='space-between'
                          padding='16px'
                          borderRadius='16px'
                          bgcolor={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#F6F8FF"
                              : "#303032"
                          }
                        >
                          {selectedNFts.map((nft) => {
                            return (
                              <Box display='flex' justifyContent='space-between'>
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    fontWeight: 400,
                                    letterSpacing: "0.25px",
                                  }}
                                  color={
                                    localStorage.getItem("mui-mode") === "light"
                                      ? "#111113"
                                      : "#F6F1F2"
                                  }
                                >
                                  {nft.title.toUpperCase()}
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    fontWeight: 400,
                                    letterSpacing: "0.25px",
                                  }}
                                  color={
                                    localStorage.getItem("mui-mode") === "light"
                                      ? "#111113"
                                      : "#F6F1F2"
                                  }
                                >
                                  {nft.quantity * nftPrices[nft.title]} {displayCoin.toUpperCase()}
                                </Typography>
                              </Box>)
                          })}
                          {/*                           <Box display='flex' justifyContent='space-between'>
                            <Typography
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 400,
                                letterSpacing: "0.25px",
                              }}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#111113"
                                  : "#F6F1F2"
                              }
                            >
                              {selectedSupernodePlan.charAt(0).toUpperCase() +
                                selectedSupernodePlan.slice(1)}{" "}
                              Plan
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 400,
                                letterSpacing: "0.25px",
                              }}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#111113"
                                  : "#F6F1F2"
                              }
                            >
                              {guardianQuant * guardianPriceByCoin}{" "}
                              {displayCoin.toUpperCase()}
                            </Typography>
                          </Box> */}

                          <Box display='flex' justifyContent='space-between'>
                            <Typography
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 400,
                                letterSpacing: "0.25px",
                              }}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#111113"
                                  : "#F6F1F2"
                              }
                            >
                              GAS fee
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 400,
                                letterSpacing: "0.25px",
                              }}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#111113"
                                  : "#F6F1F2"
                              }
                            >
                              {!isNaN(gasFee) ? gasFee : "Not Available"}{" "}
                              {!isNaN(gasFee) ? asset.toUpperCase() : ""}
                            </Typography>
                          </Box>
                          <hr />
                          <Box display='flex' justifyContent='space-between'>
                            <Typography
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 700,
                                letterSpacing: "0.25px",
                              }}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#111113"
                                  : "#F6F1F2"
                              }
                            >
                              Total
                            </Typography>
                            {!isNaN(gasFee) && (
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  fontWeight: 700,
                                  letterSpacing: "0.25px",
                                }}
                                color={
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#111113"
                                    : "#F6F1F2"
                                }
                              >
                                {" "}
                                {nftTotal}{" "}
                                {displayCoin.toUpperCase()} {` + ${gasFee}`}{" "}
                                {`${asset.toUpperCase()}`}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "164px",
                        }}
                      >
                        <PurchaseButtom
                          style={{
                            width: "100%",
                            textAlign: "center",
                            maxWidth: "300px",
                            margin: "0 auto",
                            marginTop: "32px",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "600",
                            fontSize: "15px",
                            padding: "0.65rem",
                            borderRadius: "0.75rem",
                            cursor: "pointer",
                          }}
                          disabled={!gasFee}
                          onClick={async () => {
                            setBuyingLoading(true);
                            setConfirm(true);
                            await buyNfts();
                            setBuyingLoading(false);
                          }}
                        >
                          {!isNaN(gasFee)
                            ? "Confirm Payment"
                            : "Unable to get the GasFee"}
                        </PurchaseButtom>
                        <Stack
                          flexDirection={"row"}
                          justifyContent={"center"}
                          marginTop={"12px"}
                          color={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#5F5E60"
                              : "#FFFFFF"
                          }
                        >
                          <SvgIcon component={Lock} sx={{ fontSize: "30px" }} />
                          Secure payment
                        </Stack>
                      </div>
                    </Box>
                  )}

                {isBuying &&
                  selectedPurchaseOption !== 'nft' &&
                  purchaseMode == "crypto" &&
                  totalPrice &&
                  !confirm &&
                  !buyingLoading && (
                    <Box>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginLeft: "0px",
                        }}
                      >
                        <img
                          src={leftArrow}
                          alt='back'
                          height={30}
                          width={30}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setIsBuying(false);
                            setTotalPrice(undefined);
                          }}
                        />
                        <Typography
                          fontSize='24px'
                          color='#8DA8FF'
                          fontWeight={"700"}
                        >
                          Confirm your order
                        </Typography>
                      </div>

                      <div>
                        <Typography
                          style={{
                            marginBottom: "8px",
                            marginTop: "40px",
                            fontWeight: 700,
                            fontSize: "16px",
                          }}
                          color={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#111113"
                              : "#FFFFFF"
                          }
                        >
                          Paying with
                        </Typography>
                        <Box
                          style={{ cursor: "pointer" }}
                          display='flex'
                          alignItems='center'
                          justifyContent='space-between'
                          padding='16px 32px'
                          borderRadius='16px'
                          bgcolor={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#F6F8FF"
                              : "#303032"
                          }
                        >
                          <Typography
                            variant='h6'
                            sx={{
                              fontWeight: "400",
                              fontSize: "20px",
                              color: "#fff",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={coinImage}
                                alt='pay-icon'
                                style={{
                                  cursor: "pointer",
                                  marginRight: "10px",
                                  height: "25px",
                                  width: "25px",
                                }}
                              />

                              <Typography
                                color={
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#111113"
                                    : "#FFFFFF"
                                }
                              >
                                {displayCoin.toUpperCase()}
                              </Typography>
                            </div>
                          </Typography>
                        </Box>
                        <Typography
                          style={{
                            marginBottom: "8px",
                            marginTop: "40px",
                            fontWeight: 700,
                            fontSize: "16px",
                          }}
                          color={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#111113"
                              : "#FFFFFF"
                          }
                        >
                          Wallet
                        </Typography>
                        <Box
                          style={{ cursor: "pointer" }}
                          display='flex'
                          alignItems='center'
                          justifyContent='space-between'
                          padding='8px 32px'
                          borderRadius='16px'
                          bgcolor={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#F6F8FF"
                              : "#303032"
                          }
                        >
                          <Box display='flex' gap='8px' alignItems='center'>
                            <Box display='flex' flexDirection='column'>
                              <Typography
                                variant='subtitle1'
                                fontSize={"14px"}
                                fontWeight={"500"}
                                color={
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#111113"
                                    : "#FFFFFF"
                                }
                              >
                                {currentProfile?.data?.nickname ||
                                  intl.formatMessage({
                                    id: "platform.ProfileDropdown.CurrentProfileItem.AnonymousUser",
                                  })}
                              </Typography>
                              <Typography
                                variant='body2'
                                fontSize={"12px"}
                                color={"#5F5E60"}
                              >
                                {currentProfile?.keyID?.substring(0, 2) +
                                  currentProfile?.keyID
                                    ?.substring(2, 8)
                                    .toUpperCase() +
                                  "..." +
                                  currentProfile?.keyID
                                    ?.substring(
                                      currentProfile?.keyID?.length - 6,
                                      currentProfile?.keyID?.length
                                    )
                                    .toUpperCase()}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          display='flex'
                          alignItems='center'
                          width='100%'
                          gap={{ xs: 10, sm: 34 }}
                        >
                          <Typography
                            style={{
                              marginBottom: "8px",
                              marginTop: "40px",
                              fontWeight: 700,
                              fontSize: "16px",
                            }}
                            color={
                              localStorage.getItem("mui-mode") === "light"
                                ? "#111113"
                                : "#FFFFFF"
                            }
                          >
                            Summary
                          </Typography>
                          <Box
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                            position='relative'
                          >
                            <img
                              src={clock}
                              height='16px'
                              style={{
                                position: "absolute",
                                bottom: "8.5px",
                                left: "-20px",
                              }}
                            />
                            <Typography
                              style={{
                                marginBottom: "8px",
                                marginTop: "40px",
                                fontWeight: 400,
                                fontSize: "12px",
                              }}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#111113"
                                  : "#FFFFFF"
                              }
                            >
                              Quote updates in{" "}
                              <span style={{ fontWeight: 700 }}>
                                {formatTime(timer)}
                              </span>
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          style={{ cursor: "pointer" }}
                          display='flex'
                          flexDirection='column'
                          gap='16px'
                          justifyContent='space-between'
                          padding='16px'
                          borderRadius='16px'
                          bgcolor={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#F6F8FF"
                              : "#303032"
                          }
                        >
                          <Box display='flex' justifyContent='space-between'>
                            <Typography
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 400,
                                letterSpacing: "0.25px",
                              }}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#111113"
                                  : "#F6F1F2"
                              }
                            >
                              {selectedSupernodePlan.charAt(0).toUpperCase() +
                                selectedSupernodePlan.slice(1)}{" "}
                              Plan
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 400,
                                letterSpacing: "0.25px",
                              }}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#111113"
                                  : "#F6F1F2"
                              }
                            >
                              {guardianQuant * guardianPriceByCoin}{" "}
                              {displayCoin.toUpperCase()}
                            </Typography>
                          </Box>

                          <Box display='flex' justifyContent='space-between'>
                            <Typography
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 400,
                                letterSpacing: "0.25px",
                              }}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#111113"
                                  : "#F6F1F2"
                              }
                            >
                              GAS fee
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 400,
                                letterSpacing: "0.25px",
                              }}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#111113"
                                  : "#F6F1F2"
                              }
                            >
                              {!isNaN(gasFee) ? gasFee : "Not Available"}{" "}
                              {!isNaN(gasFee) ? asset.toUpperCase() : ""}
                            </Typography>
                          </Box>
                          <hr />
                          <Box display='flex' justifyContent='space-between'>
                            <Typography
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 700,
                                letterSpacing: "0.25px",
                              }}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#111113"
                                  : "#F6F1F2"
                              }
                            >
                              Total
                            </Typography>
                            {!isNaN(gasFee) ? (
                              asset.toUpperCase() ===
                                selectedCoin.toUpperCase() ? (
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    fontWeight: 700,
                                    letterSpacing: "0.25px",
                                  }}
                                  color={
                                    localStorage.getItem("mui-mode") === "light"
                                      ? "#111113"
                                      : "#F6F1F2"
                                  }
                                >
                                  {guardianPriceByCoin * guardianQuant + gasFee}{" "}
                                  {displayCoin.toUpperCase()}
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    fontWeight: 700,
                                    letterSpacing: "0.25px",
                                  }}
                                  color={
                                    localStorage.getItem("mui-mode") === "light"
                                      ? "#111113"
                                      : "#F6F1F2"
                                  }
                                >
                                  {" "}
                                  {guardianPriceByCoin * guardianQuant}{" "}
                                  {displayCoin.toUpperCase()} {` + ${gasFee}`}{" "}
                                  {`${asset.toUpperCase()}`}
                                </Typography>
                              )
                            ) : (
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  fontWeight: 700,
                                  letterSpacing: "0.25px",
                                }}
                                color={
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#111113"
                                    : "#F6F1F2"
                                }
                              >
                                {" "}
                                -{" "}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "164px",
                        }}
                      >
                        <PurchaseButtom
                          style={{
                            width: "100%",
                            textAlign: "center",
                            maxWidth: "300px",
                            margin: "0 auto",
                            marginTop: "32px",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "600",
                            fontSize: "15px",
                            padding: "0.65rem",
                            borderRadius: "0.75rem",
                            cursor: "pointer",
                          }}
                          disabled={!gasFee}
                          onClick={async () => {
                            setBuyingLoading(true);
                            setConfirm(true);
                            await buySupernodeGuardian();
                            setBuyingLoading(false);
                          }}
                        >
                          {!isNaN(gasFee)
                            ? "Confirm Payment"
                            : "Unable to get the GasFee"}
                        </PurchaseButtom>
                        <Stack
                          flexDirection={"row"}
                          justifyContent={"center"}
                          marginTop={"12px"}
                          color={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#5F5E60"
                              : "#FFFFFF"
                          }
                        >
                          <SvgIcon component={Lock} sx={{ fontSize: "30px" }} />
                          Secure payment
                        </Stack>
                      </div>
                    </Box>
                  )}

                {buyingLoading &&
                  purchaseMode == "crypto" &&
                  purchaseStatus >= 0 &&
                  purchaseStatus < 4 && (
                    <Box>
                      <p
                        style={{
                          color: " #8DA8FF",
                          fontWeight: 700,
                          fontSize: "24px",
                          marginBottom: "24px",
                        }}
                      >
                        {" "}
                        Transaction in progress
                      </p>
                      <p
                        style={{
                          color: "#C8C6C8",
                          fontWeight: 400,
                          fontSize: "14px",
                        }}
                      >
                        Your order completion time may vary, please wait
                      </p>
                      <p
                        style={{
                          color: "#C8C6C8",
                          fontWeight: 400,
                          fontSize: "14px",
                        }}
                      >
                        and we’ll let you know when it’s completed.
                      </p>
                      <Stack
                        marginTop={"110px"}
                        height={"190px"}
                        flexDirection={"row"}
                        justifyContent={"center"}
                        position={"relative"}
                      >
                        <img
                          className='rotationClock'
                          src={dentro}
                          width={"96px"}
                          height={"96px"}
                          style={{
                            animation: "rotation 2s infinite linear",
                            top: "calc(50% - 48px)",
                            position: "absolute",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        />
                        <img
                          className='rotation'
                          src={fora}
                          width={"144px"}
                          height={"144px"}
                          style={{
                            top: "calc(50% - 72px)",
                            position: "absolute",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        />
                      </Stack>

                      {purchaseStatus == 0 && (
                        <p
                          style={{
                            color: "#C8C6C8",
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Starting Guardian Purchase...
                        </p>
                      )}
                      {purchaseStatus == 1 && (
                        <p
                          style={{
                            color: "#C8C6C8",
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Transfering asset to CONET Guardian...
                        </p>
                      )}
                      {purchaseStatus == 2 && (
                        <p
                          style={{
                            color: "#C8C6C8",
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Waiting transfering confirmation...
                        </p>
                      )}
                      {purchaseStatus == 3 && (
                        <p
                          style={{
                            color: "#C8C6C8",
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Finalizing purchase...
                        </p>
                      )}

                      <p
                        style={{
                          color: "#C8C6C8",
                          fontWeight: 500,
                          fontSize: "11px",
                          marginTop: "110px",
                          textAlign: "center",
                          lineHeight: "16px",
                          letterSpacing: "0.5px",
                        }}
                      >
                        The purchase process can take up to 24 hours, wait until
                      </p>
                      <p
                        style={{
                          color: "#C8C6C8",
                          fontWeight: 500,
                          fontSize: "11px",
                          textAlign: "center",
                          lineHeight: "16px",
                          letterSpacing: "0.5px",
                        }}
                      >
                        it’s finished, when it’s finished you’ll be notified.
                      </p>
                      <Stack>
                        <PurchaseButtom
                          style={{
                            width: "100%",
                            textAlign: "center",
                            maxWidth: "300px",
                            margin: "0 auto",
                            marginTop: "32px",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "600",
                            fontSize: "15px",
                            padding: "0.65rem",
                            borderRadius: "0.75rem",
                            cursor: "pointer",
                          }}
                        >
                          <Box
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <CircularProgress size={24} color='inherit' />{" "}
                            Processing
                          </Box>
                        </PurchaseButtom>
                      </Stack>

                      <Stack
                        flexDirection={"row"}
                        justifyContent={"center"}
                        marginTop={"12px"}
                        color={
                          localStorage.getItem("mui-mode") === "light"
                            ? "#5F5E60"
                            : "#FFFFFF"
                        }
                      >
                        <SvgIcon component={Lock} sx={{ fontSize: "30px" }} />
                        Secure payment
                      </Stack>
                    </Box>
                  )}

                {purchaseStatus < 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    gap={8}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        maxWidth: "320px",
                      }}
                      gap={2}
                    >
                      <Box
                        sx={{
                          maxWidth: "160px",
                          width: "100%",
                          height: "160px",
                          borderRadius: "50%",
                          border: "5px solid white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img src={failure} alt='success' width='32px' />
                      </Box>
                      <Typography
                        style={{ fontSize: "20px", textAlign: "center" }}
                        color={
                          localStorage.getItem("mui-mode") === "light"
                            ? "#111113"
                            : "#FFB4AB"
                        }
                      >
                        Purchase declined by CoNET
                      </Typography>
                      <Typography
                        style={{ fontSize: "14px", textAlign: "center" }}
                        color={
                          localStorage.getItem("mui-mode") === "light"
                            ? "#111113"
                            : "#FFFFFF"
                        }
                      >
                        Please, send us a message
                      </Typography>
                    </Box>

                    <Box>
                      <Typography
                        style={{ fontSize: "14px", cursor: "pointer" }}
                        onClick={async () => {
                          setPurchaseMode("");
                          setIsBuying(false);
                          setSelectedCoin("none");
                          setConfirm(false);
                          setPurchaseStatus(0);
                          setTotalPrice(1250);
                          setGuardianQuant(1);
                        }}
                        color={
                          localStorage.getItem("mui-mode") === "light"
                            ? "#111113"
                            : "#3399FF"
                        }
                      >
                        Dismiss
                      </Typography>
                    </Box>
                  </Box>
                )}

                {isBuying &&
                  totalPrice &&
                  confirm &&
                  selectedPurchaseOption !== 'nft' &&
                  purchaseMode == "crypto" &&
                  purchaseStatus > 3 && (
                    <Box>
                      <Box>
                        <img src={img5} alt='success' width='32px' />
                        <Typography
                          style={{ fontSize: "36px" }}
                          color={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#111113"
                              : "#FFFFFF"
                          }
                        >
                          The transaction{" "}
                        </Typography>
                        <p>
                          <span
                            style={{
                              color: " #8DA8FF",
                              fontWeight: 700,
                              fontSize: "36px",
                            }}
                          >
                            {" "}
                            was successful{" "}
                          </span>
                        </p>
                      </Box>
                      <div>
                        <Box
                          marginTop={"80px"}
                          style={{ cursor: "pointer" }}
                          display='flex'
                          flexDirection='column'
                          gap='16px'
                          justifyContent='space-between'
                          padding='16px'
                          borderRadius='16px'
                          bgcolor={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#F6F8FF"
                              : "#303032"
                          }
                        >
                          <Box display='flex' justifyContent='space-between'>
                            <Typography
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 400,
                                letterSpacing: "0.25px",
                              }}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#111113"
                                  : "#F6F1F2"
                              }
                            >
                              {selectedSupernodePlan.charAt(0).toUpperCase() +
                                selectedSupernodePlan.slice(1)}{" "}
                              Plan
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 400,
                                letterSpacing: "0.25px",
                              }}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#111113"
                                  : "#F6F1F2"
                              }
                            >
                              {guardianQuant * guardianPriceByCoin}{" "}
                              {displayCoin.toUpperCase()}
                            </Typography>
                          </Box>

                          <Box display='flex' justifyContent='space-between'>
                            <Typography
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 400,
                                letterSpacing: "0.25px",
                              }}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#111113"
                                  : "#F6F1F2"
                              }
                            >
                              GAS fee
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 400,
                                letterSpacing: "0.25px",
                              }}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#111113"
                                  : "#F6F1F2"
                              }
                            >
                              {parseFloat(gasFee)} {asset.toUpperCase()}
                            </Typography>
                          </Box>
                          <hr />
                          <Box display='flex' justifyContent='space-between'>
                            <Typography
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 700,
                                letterSpacing: "0.25px",
                              }}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#111113"
                                  : "#F6F1F2"
                              }
                            >
                              Total
                            </Typography>
                            {!isNaN(gasFee) ? (
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  fontWeight: 700,
                                  letterSpacing: "0.25px",
                                }}
                                color={
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#111113"
                                    : "#F6F1F2"
                                }
                              >
                                {parseFloat(gasFee)} {asset.toUpperCase()}{" "}
                                {guardianPriceByCoin * guardianQuant}{" "}
                                {displayCoin.toUpperCase()}
                              </Typography>
                            ) : (
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  fontWeight: 700,
                                  letterSpacing: "0.25px",
                                }}
                                color={
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#111113"
                                    : "#F6F1F2"
                                }
                              >
                                {" "}
                                -{" "}
                              </Typography>
                            )}
                          </Box>
                        </Box>

                        <Box
                          bgcolor={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#F6F8FF"
                              : "#303032"
                          }
                          style={{
                            borderRadius: "16px",
                            padding: "8px 16px",
                            display: "flex",
                            alignItem: "center",
                            gap: "24px",
                            marginTop: "100px",
                          }}
                        >
                          <Stack position={"relative"}>
                            <img
                              src={renderPlanLogo()}
                              width={70}
                              style={{ borderRadius: "50%" }}
                            />
                            <img
                              src={conetLogo3d}
                              width={60}
                              style={{ position: "absolute", zIndex: 2 }}
                            />
                          </Stack>

                          <Typography
                            fontSize={["18px", "24px"]}
                            fontWeight='400'
                            lineHeight='32px'
                            color={
                              localStorage.getItem("mui-mode") === "light"
                                ? "#111113"
                                : "#FFFFFF"
                            }
                          >
                            <span style={{ fontWeight: "bold" }}>
                              Congratulations!{" "}
                            </span>
                            You now have{" "}
                            <span
                              style={{ color: " #8DA8FF", fontWeight: 700 }}
                            >
                              {guardianQuant}
                            </span>{" "}
                            {selectedSupernodePlan} nodes.
                          </Typography>
                        </Box>
                        <div
                          style={{
                            marginTop: "32px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            style={{
                              color: " #8DA8FF",
                              fontWeight: 700,
                              cursor: "pointer",
                            }}
                            onClick={() => <ProfileTest />}
                          >
                            Go to profile
                          </Typography>
                          <PurchaseButtom
                            style={{
                              width: "100%",
                              textAlign: "center",
                              maxWidth: "300px",
                              margin: "8px auto",
                              justifyContent: "center",
                              alignItems: "center",
                              fontWeight: "600",
                              fontSize: "15px",
                              padding: "0.65rem",
                              borderRadius: "0.75rem",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setIsBuying(false);
                              setTotalPrice(undefined);
                              setConfirm(false);
                            }}
                          >
                            Make new one
                          </PurchaseButtom>
                          <Stack
                            flexDirection={"row"}
                            justifyContent={"center"}
                            color={
                              localStorage.getItem("mui-mode") === "light"
                                ? "#5F5E60"
                                : "#FFFFFF"
                            }
                          >
                            <SvgIcon
                              component={Lock}
                              sx={{ fontSize: "30px" }}
                            />
                            Secure payment
                          </Stack>
                        </div>
                      </div>
                    </Box>
                  )}

                {isBuying &&
                  totalPrice &&
                  confirm &&
                  purchaseMode == "crypto" &&
                  selectedPurchaseOption === 'nft' &&
                  purchaseStatus > 3 && (
                    <Box>
                      <Box>
                        <img src={img5} alt='success' width='32px' />
                        <Typography
                          style={{ fontSize: "36px" }}
                          color={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#111113"
                              : "#FFFFFF"
                          }
                        >
                          The transaction{" "}
                        </Typography>
                        <p>
                          <span
                            style={{
                              color: " #8DA8FF",
                              fontWeight: 700,
                              fontSize: "36px",
                            }}
                          >
                            {" "}
                            was successful{" "}
                          </span>
                        </p>
                      </Box>
                      <div>
                        <Box
                          marginTop={"80px"}
                          style={{ cursor: "pointer" }}
                          display='flex'
                          flexDirection='column'
                          gap='16px'
                          justifyContent='space-between'
                          padding='16px'
                          borderRadius='16px'
                          bgcolor={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#F6F8FF"
                              : "#303032"
                          }
                        >
                          {selectedNFts.map((nft) => {
                            return (
                              <Box display='flex' justifyContent='space-between'>
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    fontWeight: 400,
                                    letterSpacing: "0.25px",
                                  }}
                                  color={
                                    localStorage.getItem("mui-mode") === "light"
                                      ? "#111113"
                                      : "#F6F1F2"
                                  }
                                >
                                  {nft.title.toUpperCase()}
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    fontWeight: 400,
                                    letterSpacing: "0.25px",
                                  }}
                                  color={
                                    localStorage.getItem("mui-mode") === "light"
                                      ? "#111113"
                                      : "#F6F1F2"
                                  }
                                >
                                  {nftPrices[nft.title] * nft.quantity}
                                </Typography>
                              </Box>
                            )
                          })}
                          {/*                           <Box display='flex' justifyContent='space-between'>
                            <Typography
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 400,
                                letterSpacing: "0.25px",
                              }}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#111113"
                                  : "#F6F1F2"
                              }
                            >
                              {selectedSupernodePlan.charAt(0).toUpperCase() +
                                selectedSupernodePlan.slice(1)}{" "}
                              Plan
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 400,
                                letterSpacing: "0.25px",
                              }}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#111113"
                                  : "#F6F1F2"
                              }
                            >
                              {guardianQuant * guardianPriceByCoin}{" "}
                              {displayCoin.toUpperCase()}
                            </Typography>
                          </Box> */}

                          <Box display='flex' justifyContent='space-between'>
                            <Typography
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 400,
                                letterSpacing: "0.25px",
                              }}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#111113"
                                  : "#F6F1F2"
                              }
                            >
                              GAS fee
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 400,
                                letterSpacing: "0.25px",
                              }}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#111113"
                                  : "#F6F1F2"
                              }
                            >
                              {parseFloat(gasFee)} {asset.toUpperCase()}
                            </Typography>
                          </Box>
                          <hr />
                          <Box display='flex' justifyContent='space-between'>
                            <Typography
                              style={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 700,
                                letterSpacing: "0.25px",
                              }}
                              color={
                                localStorage.getItem("mui-mode") === "light"
                                  ? "#111113"
                                  : "#F6F1F2"
                              }
                            >
                              Total
                            </Typography>
                            {!isNaN(gasFee) ? (
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  fontWeight: 700,
                                  letterSpacing: "0.25px",
                                }}
                                color={
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#111113"
                                    : "#F6F1F2"
                                }
                              >
                                {parseFloat(gasFee)} {asset.toUpperCase()}{" "}
                                {nftTotal}{" "}
                                {displayCoin.toUpperCase()}
                              </Typography>
                            ) : (
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  fontWeight: 700,
                                  letterSpacing: "0.25px",
                                }}
                                color={
                                  localStorage.getItem("mui-mode") === "light"
                                    ? "#111113"
                                    : "#F6F1F2"
                                }
                              >
                                {" "}
                                -{" "}
                              </Typography>
                            )}
                          </Box>
                        </Box>

                        {/*                         <Box
                          bgcolor={
                            localStorage.getItem("mui-mode") === "light"
                              ? "#F6F8FF"
                              : "#303032"
                          }
                          style={{
                            borderRadius: "16px",
                            padding: "8px 16px",
                            display: "flex",
                            alignItem: "center",
                            gap: "24px",
                            marginTop: "100px",
                          }}
                        >
                          <Stack position={"relative"}>
                            <img
                              src={renderPlanLogo()}
                              width={70}
                              style={{ borderRadius: "50%" }}
                            />
                            <img
                              src={conetLogo3d}
                              width={60}
                              style={{ position: "absolute", zIndex: 2 }}
                            />
                          </Stack>

                          <Typography
                            fontSize={["18px", "24px"]}
                            fontWeight='400'
                            lineHeight='32px'
                            color={
                              localStorage.getItem("mui-mode") === "light"
                                ? "#111113"
                                : "#FFFFFF"
                            }
                          >
                            <span style={{ fontWeight: "bold" }}>
                              Congratulations!{" "}
                            </span>
                            You now have{" "}
                            <span
                              style={{ color: " #8DA8FF", fontWeight: 700 }}
                            >
                              {guardianQuant}
                            </span>{" "}
                            {selectedSupernodePlan} nodes.
                          </Typography>
                        </Box> */}
                        <div
                          style={{
                            marginTop: "32px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            style={{
                              color: " #8DA8FF",
                              fontWeight: 700,
                              cursor: "pointer",
                            }}
                            onClick={() => <ProfileTest />}
                          >
                            Go to profile
                          </Typography>
                          <PurchaseButtom
                            style={{
                              width: "100%",
                              textAlign: "center",
                              maxWidth: "300px",
                              margin: "8px auto",
                              justifyContent: "center",
                              alignItems: "center",
                              fontWeight: "600",
                              fontSize: "15px",
                              padding: "0.65rem",
                              borderRadius: "0.75rem",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setIsBuying(false);
                              setTotalPrice(undefined);
                              setConfirm(false);
                            }}
                          >
                            Make new one
                          </PurchaseButtom>
                          <Stack
                            flexDirection={"row"}
                            justifyContent={"center"}
                            color={
                              localStorage.getItem("mui-mode") === "light"
                                ? "#5F5E60"
                                : "#FFFFFF"
                            }
                          >
                            <SvgIcon
                              component={Lock}
                              sx={{ fontSize: "30px" }}
                            />
                            Secure payment
                          </Stack>
                        </div>
                      </div>
                    </Box>
                  )}
              </SuperNodeBannerBuyCardBuying>
            </Box>
          </SuperNodeBannerContent>
        </Box>
      </Box>
      <SuperNodeBannerFooter
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          flexWrap: "wrap",
        }}
      >
        <CardNodeBannerFooter>
          <BannerIcon src={img1} />
          <Typography fontSize='14px' fontWeight='600' height={"42px"}>
            {intl.formatMessage({
              id: "platform.miner.community.superNodeBanner.icon.scalability",
            })}
          </Typography>
        </CardNodeBannerFooter>
        <CardNodeBannerFooter>
          <BannerIcon src={img2} />
          <Typography fontSize='14px' fontWeight='600' height={"42px"}>
            {intl.formatMessage({
              id: "platform.miner.community.superNodeBanner.icon.global",
            })}
          </Typography>
        </CardNodeBannerFooter>
        <CardNodeBannerFooter>
          <BannerIcon src={img3} />
          {/* <Stack direction="row" alignItems="center">
          </Stack> */}
          <Typography fontSize='14px' fontWeight='600' height={"42px"}>
            {intl.formatMessage({
              id: "platform.miner.community.superNodeBanner.icon.maintenance",
            })}
          </Typography>
        </CardNodeBannerFooter>
        <CardNodeBannerFooter>
          <BannerIcon src={img4} />
          <Typography fontSize='14px' fontWeight='600' height={"42px"}>
            {intl.formatMessage({
              id: "platform.joinUS.miner.cloud.table.item4",
            })}
          </Typography>
        </CardNodeBannerFooter>
        <CardNodeBannerFooter>
          <BannerIcon src={img1} />
          <Typography fontSize='14px' fontWeight='600' height={"42px"}>
            Receive Special CoNET Airdrop
          </Typography>
        </CardNodeBannerFooter>
        <CardNodeBannerFooter>
          <BannerIcon src={cloudImage} />
          <Typography fontSize='14px' fontWeight='600' height={"42px"}>
            Own Super Cloud Nodes
          </Typography>
        </CardNodeBannerFooter>
      </SuperNodeBannerFooter>
    </SuperNodeBannerWrapper>
  );
};
